import React, {Suspense, useCallback} from 'react'
import {Path, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import {useQuery} from '@tanstack/react-query'

import {$api, Loader} from 'src/utils'
import {getValueSelects, getValueSelectsParent} from 'src/utils/endpoints'
import {ChangeLang, useDebounce} from 'src/hooks'

const SelectsItem = React.lazy(() => import('./select-item'))

interface ISelects {
  text?: string
  registerText: Path<IForm>
  type: string
  defaultValue?: Select | undefined
}

interface SelectValue {
  value: string
  innerText: string
  id: string
}

const Selects: React.FC<ISelects> = ({
  text,
  registerText,
  type,
  defaultValue,
}) => {
  const methods = useFormContext()
  const [texts, setText] = React.useState('')
  const [textSelect, setTextSelect] = React.useState('')
  const [inputOneClick, setInputOneClick] = React.useState(false)
  const [inputValue, setInputValue] = React.useState<SelectValue | string>()
  const [open, setOpen] = React.useState(false)
  const {setValue} = methods
  const debounced = useDebounce(texts)
  const debouncedModal = useDebounce(textSelect)
  const {t} = useTranslation()
  const localFilterDatas = JSON.parse(
    localStorage.getItem('filterDatas') as string,
  )
  const filterDatas =
    localFilterDatas?.filterDatas?.length && localFilterDatas?.filterDatas

  const {data: parentSelect, isLoading: parentSelectLoading} = useQuery(
    ['get-selects', type],
    async () => {
      return (await $api.get(`${getValueSelectsParent}${type}`)).data
    },
    {refetchOnMount: false},
  )

  const {data: selectSearch} = useQuery(
    ['get-selects', debounced],
    async () => {
      return (await $api.get(`${getValueSelects}${type}&keyword=${debounced}`))
        .data
    },
    {enabled: !!debounced},
  )
  const {data: selectSearchModal} = useQuery(
    ['get-selects', debouncedModal],
    async () => {
      return (
        await $api.get(`${getValueSelects}${type}&keyword=${debouncedModal}`)
      ).data
    },
    {enabled: !!debouncedModal},
  )

  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
    setTextSelect('')
  }, [])

  const clear = () => {
    setText('')
    setInputValue('')
    setInputOneClick(false)
    setValue(registerText, '')
  }

  const clikSearch = (i: Select) => {
    setInputValue(ChangeLang(i.nameRus, i.nameKyr))
    handleClose()
    setValue(registerText, i)
  }

  const inputClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.detail === 2 && setOpen(true)
    setInputOneClick(true)
  }

  window.onmouseup = () => {
    setInputOneClick(false)
  }

  const historyValue: Set<string> = new Set()
  filterDatas?.map((i: {[x: string]: SelectData}) => {
    const el = i[registerText]
    el && historyValue.add(JSON.stringify(el))
  })

  return (
    <>
      <Box sx={{display: 'flex', gap: '15px'}}>
        <Box sx={{display: 'block', width: '100%'}}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {parentSelectLoading && <Loader size={20} />}
                  <button
                    style={{
                      border: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                      background: 'none',
                      marginLeft: 'auto',
                    }}
                    type='button'
                    onClick={handleClickOpen}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                </InputAdornment>
              ),
            }}
            variant='standard'
            placeholder={text}
            // autoComplete='off'
            id='outlined-size-small'
            size='small'
            type={'text'}
            sx={selectStyle}
            fullWidth
            onClick={(e) => inputClick(e)}
            onChange={(e) => setText(e.target.value)}
            value={
              inputValue
                ? inputValue
                : defaultValue
                ? ChangeLang(defaultValue.nameRus, defaultValue.nameKyr)
                : texts
            }
          />
          <Paper
            elevation={3}
            sx={
              texts.length && !open && !inputValue
                ? {
                    display: 'block',
                    marginBottom: '20px',
                    maxHeight: '200px',
                    overflowY: 'scroll',
                  }
                : {display: 'none'}
            }
          >
            <ListItem component='div' disablePadding sx={{display: 'block'}}>
              {selectSearch?.length &&
                selectSearch.map((i: Select) => (
                  <ListItemButton
                    key={i.code}
                    onClick={() => (clikSearch(i), setText(''))}
                  >
                    <ListItemText>
                      {ChangeLang(i.nameRus, i.nameKyr)}
                    </ListItemText>
                  </ListItemButton>
                ))}
            </ListItem>
          </Paper>
          <Paper
            elevation={3}
            sx={
              inputOneClick && !texts.length
                ? {
                    display: 'block',
                    maxHeight: '200px',
                    // mb: '15px',
                    overflowY: 'scroll',
                  }
                : {display: 'none'}
            }
          >
            <ListItem component='div' disablePadding sx={{display: 'block'}}>
              {Array.from(historyValue).map((i: string, idx: number) => {
                const e = JSON.parse(i)
                return (
                  <ListItemButton
                    key={idx}
                    onClick={() => (clikSearch(e), setInputOneClick(false))}
                  >
                    <ListItemText>
                      {ChangeLang(e.nameRus, e.nameKyr)}
                    </ListItemText>
                  </ListItemButton>
                )
              })}
            </ListItem>
          </Paper>
        </Box>
        <Box sx={{cursor: 'pointer'}}>
          <ClearIcon
            onClick={clear}
            sx={{
              color: '#666666',
              fontSize: '30px',
              background: '#f8f8f8',
              p: '5px',
              borderRadius: '7px',
            }}
          />
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby='alert-dialog-description'
        sx={{maxHeight: '80%', padding: '15px'}}
      >
        <Box
          id='draggable-dialog-title'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DialogTitle>{text}</DialogTitle>
          <CloseIcon
            onClick={handleClose}
            sx={{marginRight: '20px', cursor: 'pointer'}}
          />
        </Box>
        <Suspense
          fallback={
            <Box sx={{margin: '0 auto'}}>
              <Loader size={40} />
            </Box>
          }
        >
          <DialogContent sx={{overflowX: 'hidden'}}>
            {(selectSearchModal?.length
              ? selectSearchModal
              : parentSelect
            )?.map((item: Select) => {
              return (
                <Box key={item.code}>
                  <SelectsItem selectVal={clikSearch} item={item} type={type} />
                </Box>
              )
            })}
          </DialogContent>
        </Suspense>
        <DialogActions>
          <TextField
            size='small'
            type={'text'}
            placeholder={t('search')}
            sx={{
              borderRadius: '9px',
              width: '100%',
              margin: '5px 25px 20px 25px',
            }}
            onChange={(e) => setTextSelect(e.target.value)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

const selectStyle = () => ({
  borderRadius: '9px',
  color: 'black',
  '& .MuiInputBase-input': {
    color: 'black',
  },
})

export default Selects
