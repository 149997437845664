import React from 'react'
import {Container} from '@mui/material'

import {Banner} from './banner'
import {SectionsCard} from './section-card'

export const Home: React.FC = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '70px',
      }}
    >
      <Banner />
      <SectionsCard />
    </Container>
  )
}
