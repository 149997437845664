import React, {useEffect, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import {Box, useMediaQuery, useTheme} from '@mui/material'

import {toggleLang} from 'src/redux'
import {useAppDispatch, useAppSelector} from 'src/hooks'

import {Breadcums} from '../../utils'

// import Footer from './footer'
import Header from './header'
import Toolbar from './toolbar'

const Layout = () => {
  const [close, setClose] = useState(
    localStorage.getItem('ingermationAlert') === 'off' ? true : false,
  )
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const lng = useAppSelector((state) => state.changeLang.lng)
  const {lang} = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (lang && (lang === 'kg' || lang === 'ru')) {
      dispatch(toggleLang(lang))
    }
  }, [lang])

  useEffect(() => {
    if (
      !(lang && (lang === 'kg' || lang === 'ru')) &&
      window.location.pathname !== '/'
    ) {
      navigate('/not-found')
    } else {
      navigate(
        location.pathname.includes('/kg') || location.pathname.includes('/ru')
          ? {
              pathname: `${location.pathname
                .replace('kg', lng)
                .replace('ru', lng)}`,
              search: location.search,
              hash: location.hash,
            }
          : {pathname: lng, search: location.search, hash: location.hash},
        {replace: true},
      )
    }
  }, [lng])

  const onClose = () => {
    localStorage.setItem('ingermationAlert', 'off')
    setClose(true)
  }

  return (
    <>
      {/* <Box
        sx={{
          textAlign: 'center',
          color: '#f35454',
          display: close ? 'none' : 'block',
          fontSize: '14px',
          m: '10px 0',
        }}
      >
        <Box
          display={'flex'}
          mb={'-20px'}
          className='kuba'
          justifyContent={'center'}
          gap={mobile ? '10px' : '30px'}
        >
          Внимание ! Информационно-правовая система «Централизованный банк
          данных правовой информации » с 18 декабря 2023 года работает в
          пилотно-тестовом режиме
          <CloseIcon
            onClick={onClose}
            sx={{mt: mobile ? '' : '-8px', cursor: 'pointer'}}
          />
        </Box>
        <br /> <hr />
        Көңүл буруңуздар! 2023-жылдын 18-декабрынан тартып «Укуктук
        маалыматтардын борборлоштурулган банкы» маалыматтык укуктук системасы
        пилоттук-тесттик режимде иштөөдө
      </Box> */}
      <Toolbar />
      <Header />
      <Breadcums />
      <Outlet />
      {/* <Footer /> */}
    </>
  )
}

export default Layout
