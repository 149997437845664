import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Button,
  Divider,
  Modal,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {useQuery} from '@tanstack/react-query'

import {$api, Loader} from 'src/utils'
import {popularRequests} from 'src/utils/endpoints'
import {ChangeLang} from 'src/hooks'

interface IFavoriteDocs {
  count: number
  documentCode: number
  nameKg: string
  nameRu: string
  lastEdition: number
  status: string
}

export const PopularDocs = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const theme = useTheme()
  const {lang} = useParams()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  const {data: popularDocs, isLoading} = useQuery(
    ['populars'],
    async () => {
      return (await $api.get(popularRequests)).data
    },
    {refetchOnMount: false, refetchOnWindowFocus: false, enabled: open},
  )

  const {t} = useTranslation()
  return (
    <Box>
      <Button onClick={handleOpen}>
        <Typography sx={favouriteQueries}>
          {t('pageNames.popularQueries')}
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={popular(mobile)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {t('pageNames.popularQueries')}
          </Typography>
          <hr style={{marginBottom: '20px'}} />
          {isLoading ? (
            <Box sx={{display: 'flex', mt: '50px', justifyContent: 'center'}}>
              <Loader size={40} />
            </Box>
          ) : (
            <ul>
              {popularDocs &&
                popularDocs?.map((popularDoc: IFavoriteDocs, idx: number) => {
                  return (
                    <Box key={idx} sx={typographyLink}>
                      <a
                        href={`/${popularDoc.documentCode}/edition/${popularDoc.lastEdition}/${lang}`}
                        style={{
                          textDecoration: 'none',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{mr: mobile ? '15px' : '20px'}}>
                          {popularDoc.status ? (
                            popularDoc.status === 'Действует' ? (
                              <CheckIcon
                                color='success'
                                fontSize={mobile ? 'medium' : 'large'}
                              />
                            ) : popularDoc.status !== 'Не вступил в силу' ? (
                              <ClearIcon
                                fontSize={mobile ? 'medium' : 'large'}
                                sx={{color: '#d53030'}}
                              />
                            ) : (
                              <Box width={mobile ? '25px' : '35px'} />
                            )
                          ) : (
                            <Box width={mobile ? '25px' : '35px'} />
                          )}
                        </Box>
                        <Typography
                          sx={{display: 'inline', paddingBottom: '10px'}}
                          component='span'
                          variant='body2'
                          color='text.primary'
                        >
                          {ChangeLang(popularDoc.nameRu, popularDoc.nameKg)}
                        </Typography>
                      </a>
                      <Divider sx={{mb: '10px'}} />
                    </Box>
                  )
                })}
            </ul>
          )}
        </Box>
      </Modal>
    </Box>
  )
}

const popular = (mobile: boolean) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: mobile ? 2 : 4,
  borderRadius: '10px',
  overflowY: 'scroll',
  height: '600px',
})

const typographyLink = () => ({
  transition: '0.3s',
  '&:hover': {
    background: '#eff4fc',
    transform: 'translateY(-4px)',
    transition: '0.3s',
    boxShadow: 1,
  },
})

const favouriteQueries = (theme: Theme) => ({
  color: 'white',
  fontSize: '13px',
  // fon
  [theme.breakpoints.down(1000)]: {
    fontSize: '16px',
    ml: '8px',
    color: 'black',
  },
})
