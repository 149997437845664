import React from 'react'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box/Box'

import {DatesGroup, Fields, Selects} from '../../index'

const AdvancedSearch: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Box>
      <DatesGroup
        text={t('form.dateOfEntry')}
        exactDate='dateOfEntry'
        dateFrom='dateOfEntryFrom'
        datedTo='dateOfEntryTo'
      />
      <DatesGroup
        text={t('form.dateAdoptedFrom')}
        exactDate='dateLimit'
        dateFrom='dateLimitFrom'
        datedTo='dateLimitTo'
      />
      <DatesGroup
        text={t('form.dateAdoptedTo')}
        exactDate='dateInclusion'
        dateFrom='dateInclusionFrom'
        datedTo='dateInclusionTo'
      />
      <DatesGroup
        text={t('form.dateOfPublication')}
        exactDate='datePublication'
        dateFrom='datePublicationFrom'
        datedTo='datePublicationTo'
      />
      <Fields
        text={t('form.numberOfPublication')}
        registerText='numberPublication'
      />
      <Selects
        type='19'
        text={t('form.publicationSource')}
        registerText='sourcePublicationsId'
      />
      <DatesGroup
        text={t('form.nateOfRegisMU')}
        exactDate='dateRegistration'
        dateFrom='dateRegistrationFrom'
        datedTo='dateRegistrationTo'
      />
      <Fields
        text={t('form.numberOfRegisMU')}
        registerText='numberRegistration'
      />
    </Box>
  )
}

export default AdvancedSearch
