import React from 'react'
import {useParams} from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {Box, useMediaQuery, useTheme} from '@mui/material'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import {ChangeLang} from 'src/hooks'

import {AddElectedDocs} from './methods/add-elected'

interface IProps {
  item: IListDoc
  formData: {nameRus: string; nameKyr: string}
}

const ListDoc: React.FC<IProps> = ({item, formData}) => {
  const theme = useTheme()
  const {lang} = useParams()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  const contentRu = item.nameRu?.replace(
    eval('/' + formData?.nameRus + '/gi'),
    (item: string) => {
      return `<strong style="color: red; font-weight: bold;">${item}</strong>`
    },
  )
  const contentKg = item.nameKg?.replace(
    eval('/' + formData?.nameKyr + '/gi'),
    (item: string) => {
      return `<strong style="color: red; font-weight: bold;">${item}</strong>`
    },
  )

  return (
    <Box sx={typographyLink}>
      <ListItem
        alignItems='flex-start'
        sx={{
          display: 'flex',
          p: mobile ? '5px 10px' : '',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <a
          href={`/${item.documentCode}/edition/${item.lastEdition}/${lang}`}
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Manrope, sans-serif',
          }}
        >
          <Box sx={{mr: mobile ? '15px' : '20px'}}>
            {item.status ? (
              item.status === 'Действует' ? (
                <CheckIcon
                  color='success'
                  fontSize={mobile ? 'medium' : 'large'}
                />
              ) : item.status !== 'Не вступил в силу' ? (
                <ClearIcon
                  fontSize={mobile ? 'medium' : 'large'}
                  sx={{color: '#d53030'}}
                />
              ) : (
                <Box width={mobile ? '25px' : '35px'} />
              )
            ) : (
              <Box width={mobile ? '25px' : '35px'} />
            )}
          </Box>
          <Typography
            sx={{display: 'inline', fontSize: mobile ? '14px' : ''}}
            component='span'
            variant='body2'
            color='text.primary'
            dangerouslySetInnerHTML={{
              __html: ChangeLang(contentRu, contentKg),
            }}
          />
        </a>
        <AddElectedDocs doc={item} />
      </ListItem>
      <Divider variant='inset' component='li' />
    </Box>
  )
}

const typographyLink = () => ({
  transition: '0.3s',
  '&:hover': {
    background: '#eff4fc',
    transform: 'translateY(-4px)',
    transition: '0.3s',
    boxShadow: 1,
  },
})

export default ListDoc
