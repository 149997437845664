import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {Box, Button, Chip, Container} from '@mui/material'

import {esiLogout} from 'src/utils/endpoints'

import {DocHistory} from './doc-history'

export const Profile = () => {
  const user = JSON.parse(localStorage.getItem('esi') as string)
  const logout = () => {
    localStorage.removeItem('randomId')
    const newWindow = window.open(
      'https://esia.tunduk.kg/connect/endsession',
      'connectWindow',
      'width=1,height=1,scrollbars=yes',
    )
    setTimeout(() => {
      newWindow?.close()
      window.open(esiLogout)
    }, 500)
  }

  return (
    <Container
      sx={{
        mt: '50px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <Box>
        <Box textAlign={'center'}>
          <AccountCircleIcon
            color='primary'
            sx={{fontSize: '100px', mb: '40px'}}
          />
          <Box
            sx={{display: 'flex', flexWrap: 'wrap', gap: '20px', mb: '40px'}}
          >
            <Chip
              color='primary'
              sx={{fontSize: '18px', p: '10px'}}
              label={user?.name}
            />
            <Chip
              color='primary'
              sx={{fontSize: '18px', p: '10px'}}
              label={user?.phoneNumber}
            />
            <Chip
              color='primary'
              sx={{fontSize: '18px', p: '10px'}}
              label={user?.email}
            />
          </Box>
          <Button variant='contained' onClick={logout}>
            Выйти
          </Button>
        </Box>
      </Box>
      <Box width='100%'>
        <DocHistory />
      </Box>
    </Container>
  )
}
