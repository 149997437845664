import React from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Theme, Typography} from '@mui/material'

import {ChangeLang} from 'src/hooks'
import {IDoc} from 'src/models/doc'

interface Props {
  id: string | undefined
  doc: IDoc
}

export const Expired = ({doc}: Props) => {
  const isExpired = doc?.status?.nameRus === 'Утратил силу'
  const {t} = useTranslation()

  return (
    <Box sx={errorTextTitles}>
      {doc?.data && (
        <Typography>
          {doc?.headerRu ||
            (doc?.headerKg &&
              doc.headerRu &&
              `${ChangeLang(doc?.headerRu, doc?.headerKg)}`)}
          {new Date(doc?.data).toLocaleDateString('ru-RU')}
        </Typography>
      )}
      {isExpired && (
        <Typography
          sx={{
            color: 'red',
            pointerEvents: 'none',
          }}
        >
          {t('doc.expired')}
        </Typography>
      )}
    </Box>
  )
}

const errorTextTitles = (theme: Theme) => ({
  maxWidth: '100%',
  // alignItems: 'center',
  textAlign: 'center',
  display: 'block',
  [theme.breakpoints.between('xs', 'md')]: {
    // display: 'none',
  },
})
