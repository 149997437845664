import React, {useEffect, useRef, useState} from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {Container} from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import {useQuery} from '@tanstack/react-query'
import axios, {AxiosResponse} from 'axios'

import {$api} from '../../utils'
import {
  downloadExcelForStatistic,
  downloadWordForStatistic,
  getStatistics,
  getStatisticsDiagram,
} from '../../utils/endpoints'

import AllStatistics from './all-statistics'
import VisitUsers from './visit-users'

export const Statistics = () => {
  const [value, setValue] = React.useState<string>('1')
  const [formData, setFormData] = React.useState<
    null | SendDataForGetStatistics | SendDataForGetByVisit
  >()
  const [array, setArray] = useState<
    StatisticSelectors[] | null | {statisticClassificatorName: string}[]
  >([])
  const prevRef: React.MutableRefObject<string | undefined> = useRef()

  const filterArray = array?.filter(Boolean)

  const getStatistic = {
    ...formData,
    statisticSelectors: filterArray?.length ? filterArray : [],
  }

  const {data, refetch, isFetching, isError} = useQuery(
    [formData, value],
    async () => {
      return (
        await $api.post(getStatisticsDiagram, getStatistic, {
          headers: {'Content-Type': 'application/json'},
        })
      ).data
    },
    {enabled: !!formData},
  )

  useEffect(() => {
    prevRef.current = value
  }, [value])

  const condition = value === prevRef.current && data

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    setFormData(null)
    setArray(null)
  }

  const downloadFile = async (type: string) => {
    await axios
      .post(
        `${
          type === 'word' ? downloadWordForStatistic : downloadExcelForStatistic
        }`,
        getStatistic,
        {
          responseType: 'blob',
        },
      )
      .then((response: AxiosResponse) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }),
        )
        link.download = `${
          type === 'word' ? 'statistic.doc' : 'statistic.xlsx'
        }`
        document.body.appendChild(link)
        link.click()
      })
  }

  return (
    <Container>
      <TabContext value={value}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            {/* <Tab label='Статистика' value='1' /> */}
            <Tab label='Статистика по посещению' value='1' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          {/* <AllStatistics
            isError={isError}
            data={condition}
            setFormData={setFormData}
            setArray={setArray}
            refetch={refetch}
            isFetching={isFetching}
            downloadFile={downloadFile}
          /> */}
          <VisitUsers />
        </TabPanel>
        {/* <TabPanel value='2'>
        </TabPanel> */}
      </TabContext>
    </Container>
  )
}
