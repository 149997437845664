/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react'
import {Path, useFormContext} from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear'
import {Box, Paper, TextField} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

interface IFields {
  text: string
  registerText: Path<IForm>
  inputType?: string
  calarifyValue?: string
}

const Fields: React.FC<IFields> = ({
  text,
  registerText,
  inputType = 'text',
  calarifyValue,
}) => {
  const methods = useFormContext()
  const {register, resetField} = methods
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState<string>('')

  useEffect(() => {
    calarifyValue && setValue(calarifyValue)
  }, [calarifyValue])

  const clear = () => {
    resetField(registerText)
    setValue('')
  }
  window.onmouseup = () => {
    setOpen(false)
  }
  const localFilterDatas = JSON.parse(
    localStorage.getItem('filterDatas') as string,
  )
  const filterDatas =
    localFilterDatas?.filterDatas &&
    localFilterDatas?.filterDatas.length &&
    localFilterDatas?.filterDatas

  const clickValue = (e: string) => {
    setValue(e)
    methods.setValue(registerText, e)
  }

  const uniqueValue: Set<string> = new Set()
  const historyValue: Select[] = []

  filterDatas?.map((i: {[x: string]: SelectData}) => {
    const el = i[registerText]
    el && uniqueValue.add(JSON.stringify(el))
  })
  uniqueValue.forEach((i) => {
    historyValue.push(JSON.parse(i))
  })

  return (
    <Box sx={{display: 'flex', gap: '15px'}}>
      <Box sx={{width: '100%'}}>
        <TextField
          id='outlined-size-small'
          size='small'
          type={inputType}
          value={value}
          variant='standard'
          sx={{borderRadius: '9px', width: '100%', p: 0}}
          fullWidth
          {...register(registerText)}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => setValue(e.target.value)}
          placeholder={text}
          onClick={() => setOpen(true)}
        />
        <Paper
          elevation={3}
          sx={
            open
              ? {
                  display: 'block',
                  marginBottom: '10px',
                  maxHeight: '200px',
                  overflowY: 'scroll',
                }
              : {display: 'none'}
          }
        >
          <ListItem component='div' disablePadding sx={{display: 'block'}}>
            {historyValue?.map((el: any, idx: number) => {
              if (el?.includes(value)) {
                return (
                  <ListItemButton
                    sx={{height: '35px'}}
                    key={idx}
                    onClick={() => clickValue(el)}
                  >
                    <ListItemText>{el}</ListItemText>
                  </ListItemButton>
                )
              } else
                <ListItemButton
                  sx={{height: '35px'}}
                  key={idx}
                  onClick={() => clickValue(el)}
                >
                  <ListItemText>{el}</ListItemText>
                </ListItemButton>
            })}
          </ListItem>
        </Paper>
      </Box>

      <Box sx={{cursor: 'pointer'}}>
        <ClearIcon
          onClick={clear}
          sx={{
            color: '#666666',
            fontSize: '30px',
            background: '#f8f8f8',
            p: '5px',
            borderRadius: '7px',
          }}
        />
      </Box>
    </Box>
  )
}

export default Fields
