import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {Box, Theme, Typography, useMediaQuery, useTheme} from '@mui/material'

import BlueLogo from 'src/assets/images/layout/blue-logo.webp'
import Line from 'src/assets/images/layout/logo-line.webp'
import WhiteLogo from 'src/assets/images/layout/logo-white.webp'

export const LogoComponent = () => {
  const {t} = useTranslation()
  const {lang} = useParams()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  return (
    <Box
      component={'a'}
      href={`/${lang}`}
      sx={{
        display: 'flex',
        textDecoration: 'none',
        gap: mobile ? '15px' : '10px',
      }}
    >
      <img
        src={mobile ? WhiteLogo : BlueLogo}
        alt='logo'
        style={{width: mobile ? '106px' : '91px'}}
      />
      <img src={Line} alt='logo' style={{height: mobile ? '65px' : '53px'}} />
      <Box>
        <Typography sx={kgTitle(theme, mobile)}>{t('header.kg')}</Typography>
        <Typography sx={minjustTitle(theme, mobile)}>
          {t('header.minjustTitle')}
        </Typography>
      </Box>
    </Box>
  )
}

const kgTitle = (theme: Theme, mobile: boolean) => ({
  fontSize: '9.5px',
  textTransform: 'uppercase',
  mb: '3px',
  color: mobile ? 'white' : theme.palette.primary.main,
})

const minjustTitle = (theme: Theme, mobile: boolean) => ({
  fontSize: mobile ? '17px' : '16px',
  fontWeight: '600',
  lineHeight: mobile ? '20px' : '17px',
  textTransform: 'uppercase',
  color: mobile ? 'white' : theme.palette.primary.main,
  width: '150px',
})
