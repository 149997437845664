import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import {Box, Divider, Typography} from '@mui/material'

const ImportantDocs = () => {
  const {t} = useTranslation()
  const {lang} = useParams()

  const popularDocs = [
    {
      link: '3-22',
      title: t('importantDocs.1'),
      lastEdition: 1220610,
    },
    {
      link: '3-1',
      title: t('importantDocs.2'),
      lastEdition: 1263361,
    },
    {
      link: '3-39',
      title: t('importantDocs.3'),
      lastEdition: 1286442,
    },
    {
      link: '3-29',
      title: t('importantDocs.4'),
      lastEdition: 1261675,
    },
    {
      link: '3-2',
      title: t('importantDocs.5'),
      lastEdition: 1281648,
    },
    {
      link: '3-38',
      title: t('importantDocs.6'),
      lastEdition: 2087,
    },
    {
      link: '3-36',
      title: t('importantDocs.7'),
      lastEdition: 2102,
    },
    {
      link: '3-37',
      title: t('importantDocs.8'),
      lastEdition: 2086,
    },
    {
      link: '112303',
      title: t('importantDocs.9'),
      lastEdition: 1289504,
    },
    {
      link: '1-2',
      title: t('importantDocs.10'),
      lastEdition: 1202952,
    },
    {
      link: '3-5',
      title: t('importantDocs.11'),
      lastEdition: 1285814,
    },
    {
      link: '4-4017',
      title: t('importantDocs.12'),
      lastEdition: 1237790,
    },
    {
      link: '3-28',
      title: t('importantDocs.13'),
      lastEdition: 1265033,
    },
  ]

  return (
    <Box sx={important}>
      <Typography id='modal-modal-title' variant='h6' component='h2'>
        {t('importantDocs.title')}
      </Typography>
      <Box sx={ulStyle}>
        {popularDocs?.map((popularDoc, idx: number) => (
          <Box key={idx} sx={typographyLink}>
            <a
              href={`/${popularDoc.link}/edition/${popularDoc.lastEdition}/${lang}`}
              style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                marginTop: '10px',
                fontFamily: 'Manrope, sans-serif',
              }}
            >
              <CheckIcon color='success' fontSize='medium' />
              <Box>
                <Typography
                  sx={typography}
                  component='span'
                  color='text.primary'
                >
                  {popularDoc.title}
                </Typography>
              </Box>
            </a>
            <Divider variant='inset' component='li' />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const important = () => ({
  mt: 4,
  padding: '15px',
  background: 'white',
  borderRadius: '8px',
  maxWidth: '700px',
  boxShadow: '1',
})
const ulStyle = () => ({
  height: '500px',
  overflow: 'auto',
})
const typography = () => ({
  fontSize: '14px',
})
const typographyLink = () => ({
  listStyle: 'none',
  transition: '0.3s',
  padding: '7px 5px',
  '&:hover': {
    background: '#eff4fc',
    transform: 'translateY(-4px)',
    transition: '0.3s',
    boxShadow: 1,
    borderRadius: 2,
  },
})

export default ImportantDocs
