import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {Box} from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import {ChangeLang} from 'src/hooks'

import {AddElectedDocs} from '../../list-docs/methods/add-elected'

interface Props {
  docs: IRegisterDocs
}

export function TableOfNpa({docs}: Props) {
  const {t} = useTranslation()
  const {lang} = useParams()

  return (
    <>
      {docs?.data ? (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
          <TableContainer sx={{maxHeight: 440}}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns(t).map((column) => {
                    return (
                      <TableCell
                        key={column}
                        align={'left'}
                        sx={{fontWeight: '500'}}
                      >
                        {column}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {docs.data &&
                  docs.data.map((row, id) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={id}>
                        <TableCell>{id + 1}</TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              sx={{
                                display: 'inline',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {row.regionCode}
                            </Typography>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              sx={{
                                display: 'inline',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {row.dateRegistration}
                            </Typography>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              sx={{
                                display: 'inline',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {row.organ}
                            </Typography>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  display: 'inline',
                                  '&:hover': {
                                    color: 'red',
                                  },
                                }}
                                component='span'
                                variant='body2'
                                color='text.primary'
                                dangerouslySetInnerHTML={{
                                  __html: ChangeLang(row.nameRu, row.nameKyr),
                                }}
                              />
                            </Box>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              sx={{
                                display: 'inline',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {dayjs(row.dateAdopted).format('DD-MM-YYYY')}
                              <br /> № {row.aktNumberRu}
                            </Typography>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              sx={{
                                display: 'inline',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {row.source}
                              <br />{' '}
                              {dayjs(row.datePublication).format('DD-MM-YYYY')}
                            </Typography>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              sx={{
                                display: 'inline',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {row.status}
                            </Typography>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/${row.documentCode}/edition/${row.lastEdition}/${lang}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              sx={{
                                display: 'inline',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {row.dateInclusion}
                            </Typography>
                          </a>
                        </TableCell>
                        <TableCell>
                          <AddElectedDocs doc={row} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : null}
    </>
  )
}

const columns = (w: (w: string) => string) => [
  '№',
  w('npaTitles.codeRegion'),
  w('npaTitles.datePostNpa'),
  w('npaTitles.vidNaim'),
  w('npaTitles.nameAct'),
  w('npaTitles.dateAdopNumber'),
  w('npaTitles.whoDate'),
  w('npaTitles.status'),
  w('npaTitles.gosRegister'),
  w('npaTitles.saved'),
]
