import React from 'react'

import {
  DocPage,
  FeedbackPage,
  HomePage,
  ListDocPage,
  NewDocsPage,
  ProfilePage,
  RegisterNpaPage,
  SearchDocs,
  SelectedDocsPage,
  StatisticPage,
  Synopsis,
} from '../pages'

type RouteT = {
  path: string
  breadcrumb: string | (() => JSX.Element)
  page: React.ReactNode
}

export const routes: RouteT[] = [
  {
    path: '/:lang',
    breadcrumb: '/',
    page: <HomePage />,
  },
  {
    path: '/search-docs/:lang',
    breadcrumb: 'search-docs',
    page: <SearchDocs />,
  },
  {
    path: '/synopsis/:lang',
    breadcrumb: 'synopsis',
    page: <Synopsis />,
  },
  {
    path: '/list-docs/:lang',
    breadcrumb: 'list-docs',
    page: <ListDocPage />,
  },
  {
    path: '/:id/edition/:redaction/:lang',
    breadcrumb: 'DocName',
    page: <DocPage />,
  },
  {
    path: '/feedback/:lang',
    breadcrumb: 'pageNames.feedback',
    page: <FeedbackPage />,
  },
  {
    path: '/selected-docs/:lang',
    breadcrumb: 'pageNames.selectedDocs',
    page: <SelectedDocsPage />,
  },
  {
    path: '/notional-register-npa/:lang',
    breadcrumb: 'Государственный реестр НПА',
    page: <RegisterNpaPage />,
  },
  {
    path: '/new-docs/:lang',
    breadcrumb: 'pageNames.newDocs',
    page: <NewDocsPage />,
  },
  {
    path: '/statistics/:lang',
    breadcrumb: 'pageNames.statistics',
    page: <StatisticPage />,
  },
  {
    path: '/profile',
    breadcrumb: 'pageNames.profile',
    page: <ProfilePage />,
  },
]
