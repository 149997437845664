import React, {useState} from 'react'
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha'
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import TextsmsIcon from '@mui/icons-material/Textsms'
import {
  Avatar,
  Box,
  Button,
  Snackbar,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import MuiAlert, {AlertProps} from '@mui/material/Alert'

import {$api} from 'src/utils'
import {postFeedback} from 'src/utils/endpoints'

interface IFeedback {
  personFullName: string
  personEmail: string
  content: string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const FeedbackForm = () => {
  const user = JSON.parse(localStorage.getItem('esi') as string)
  const {t} = useTranslation()
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [openPending, setOpenPending] = useState<boolean>(false)
  const [token, setToken] = useState<string | null>('')

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
  } = useForm({
    defaultValues: {
      personFullName: user?.name,
      personEmail: user?.email,
      content: '',
    },
  })
  const fields = watch(['personFullName', 'personEmail', 'content']).filter(
    Boolean,
  )

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenPending(false)
    setOpenError(false)
    setOpenSuccess(false)
  }

  const submitHandler: SubmitHandler<IFeedback | FieldValues> = async (
    feedbackData,
  ) => {
    const body = {
      reCaptchaToken: token,
      ...feedbackData,
    }
    setOpenPending(true)
    try {
      ;(
        await $api.post(postFeedback, body, {
          headers: {'Content-type': 'application/json'},
        })
      ).data
      reset()
      setToken('')
      setOpenSuccess(true)
    } catch (e) {
      setOpenError(true)
    }
    setOpenPending(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '50px',
      }}
    >
      <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
        <TextsmsIcon />
      </Avatar>
      <Typography component='h1' fontSize='22px'>
        {t('feedback')}
      </Typography>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Stack>
          <Snackbar
            open={openPending}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity='info' sx={{width: '100%'}}>
              {t('sending')}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openError}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity='error' sx={{width: '100%'}}>
              {t('sendError')}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSuccess}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{width: '100%'}}
            >
              {t('successSend')}
            </Alert>
          </Snackbar>
        </Stack>
        <TextField
          type='text'
          margin='normal'
          {...register('personFullName')}
          fullWidth
          required={true}
          disabled={!!user}
          value={user?.name && user?.name}
          label={t('name')}
          name='personFullName'
          autoComplete='name'
          autoFocus
          size={'small'}
          sx={inputStyle}
        />
        <br />
        <TextField
          {...register('personEmail', {
            pattern:
              // eslint-disable-next-line no-useless-escape
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          type='text'
          margin='normal'
          required={true}
          fullWidth
          label='E-mail'
          disabled={!!user}
          value={user?.email && user?.email}
          name='personEmail'
          size={'small'}
          sx={inputStyle}
        />
        <Typography variant={'body2'} sx={{color: 'red'}}>
          {errors.personEmail && t('errorMail')}
        </Typography>
        <TextField
          {...register('content')}
          type='text'
          margin='normal'
          required={true}
          fullWidth
          label={t('message')}
          name='content'
        />
        {user && watch('content') && (
          <ReCAPTCHA
            sitekey='6LfLThwkAAAAAHHgapQtuwhdNn4_TdTHQ3jOcDzX'
            onChange={(token) => setToken(token)}
          />
        )}
        {!user && fields.length === 3 && (
          <ReCAPTCHA
            sitekey='6LfLThwkAAAAAHHgapQtuwhdNn4_TdTHQ3jOcDzX'
            onChange={(token) => setToken(token)}
          />
        )}
        <Box component={'div'}>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{mt: 3, mb: 5}}
            disabled={!token}
          >
            {t('send')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

const inputStyle = (theme: Theme) => ({
  width: 800,
  [theme.breakpoints.between('sm', 'md')]: {
    width: '500px',
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    width: '400px',
  },
})
