import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Container,
  Pagination,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import {ChangeLang} from 'src/hooks'
import {IDoc} from 'src/models/doc'

interface IReferenceDoc {
  doc: IDoc
}

interface IDocumentReferences {
  documentCode: number
  editionCode: number
  nameKg: string
  nameRu: string
  status: string
  lastEdition?: number
}

const ReferencesDocs: React.FC<IReferenceDoc> = ({doc}) => {
  const [pagNum, setPagNum] = useState<number>(1)
  const {lang} = useParams()
  const pagePagCount = Math.ceil(doc?.documentReferences?.length / 20)
  const totalCount: number | undefined = pagNum === 1 ? pagNum - 1 : pagNum
  const countPage = Number(totalCount) + 19
  const arr = doc?.documentReferences?.slice(totalCount, countPage)
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  return (
    <Container sx={{padding: mobile ? 0 : ''}}>
      <List sx={listDocsSx}>
        <Box sx={{display: 'flex', justifyContent: 'end'}}>
          <Pagination
            count={pagePagCount - 1}
            onChange={(_: unknown, num: number) => setPagNum(num)}
            shape='rounded'
            color='primary'
            page={pagNum}
          />
        </Box>
        <Box>
          {doc && arr?.length ? (
            arr.map((item: IDocumentReferences, idx: number) => {
              return (
                <Box key={idx} sx={typographyLink}>
                  <ListItem alignItems='flex-start'>
                    <a
                      href={`/${item.documentCode}/edition/${item.lastEdition}/${lang}`}
                      style={{
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Manrope, sans-serif',
                      }}
                    >
                      <Box sx={{mr: mobile ? '15px' : '20px'}}>
                        {item.status ? (
                          item.status === 'Действует' ? (
                            <CheckIcon
                              color='success'
                              fontSize={mobile ? 'medium' : 'large'}
                            />
                          ) : item.status !== 'Не вступил в силу' ? (
                            <ClearIcon
                              fontSize={mobile ? 'medium' : 'large'}
                              sx={{color: '#d53030'}}
                            />
                          ) : (
                            <Box width={mobile ? '25px' : '35px'} />
                          )
                        ) : (
                          <Box width={mobile ? '25px' : '35px'} />
                        )}
                      </Box>
                      <Box>
                        <Typography
                          sx={{display: 'inline'}}
                          component='span'
                          variant='body2'
                          color='text.primary'
                        >
                          {ChangeLang(item?.nameRu, item?.nameKg)}
                        </Typography>
                      </Box>
                    </a>
                  </ListItem>
                  <Divider variant='inset' component='li' />
                </Box>
              )
            })
          ) : (
            <Typography variant={'h6'} sx={{textAlign: 'center', mt: 6, mb: 6}}>
              Не найдено ссылающиеся документов
            </Typography>
          )}
        </Box>
      </List>
    </Container>
  )
}

const listDocsSx = () => ({
  width: '100%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  mb: 6,
})

const typographyLink = () => ({
  transition: '0.3s',
  '&:hover': {
    background: '#eff4fc',
    transform: 'translateY(-4px)',
    transition: '0.3s',
    boxShadow: 1,
  },
})

export default ReferencesDocs
