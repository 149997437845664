import * as React from 'react'
import {useState} from 'react'
import ReactDiffViewer from 'react-diff-viewer-continued'
import {useLocation} from 'react-router-dom'
import {Box, Container, Typography} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {useQuery} from '@tanstack/react-query'

import {$api, Loader} from 'src/utils'
import {convertToTextLog, getEditionHistory} from 'src/utils/endpoints'

interface EditionHistoryModel {
  id: number
  createdBy: string
  createdAt: string
}

export const CompareEditorialHistory = () => {
  const [firstEditorial, setFirstEditorial] = useState('')
  const [secondEditorial, setSecondEditorial] = useState('')
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const chooseEditionFirst = (e: {
    target: {value: React.SetStateAction<string>}
  }) => {
    setFirstEditorial(e.target.value)
  }
  const chooseEditionSecond = (e: {
    target: {value: React.SetStateAction<string>}
  }) => {
    setSecondEditorial(e.target.value)
  }

  const prov = firstEditorial && secondEditorial
  const {data: doc} = useQuery(
    ['edition-history'],
    async () =>
      (
        await $api.get<EditionHistoryModel[]>(getEditionHistory, {
          params: {refId: params.get('refId'), lang: params.get('lang')},
        })
      ).data,
  )
  const {
    data: url1,
    isFetching,
    isError,
  } = useQuery(
    [firstEditorial, secondEditorial],
    async () => {
      return (
        await $api.get(
          `${convertToTextLog}logId1=${firstEditorial}&logId2=${secondEditorial}&lang=${params.get(
            'lang',
          )}`,
        )
      ).data
    },
    {enabled: !!prov},
  )

  enum DiffMethod {
    CHARS = 'diffChars',
    WORDS = 'diffWords',
    WORDS_WITH_SPACE = 'diffWordsWithSpace',
    LINES = 'diffLines',
    TRIMMED_LINES = 'diffTrimmedLines',
    SENTENCES = 'diffSentences',
    CSS = 'diffCss',
  }

  return (
    <Container>
      <form onSubmit={(event) => event.preventDefault()}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            mt: '40px',
            mb: '50px',
            gap: 100,
          }}
        >
          <FormControl sx={{minWidth: 140, m: 1, height: 1}} size='small'>
            <InputLabel>Редакция-1</InputLabel>
            <Select
              labelId='demo-select-small'
              id='demo-select-small'
              value={firstEditorial}
              label='Редакция-1'
              onChange={chooseEditionFirst}
              required={true}
            >
              {!doc
                ? null
                : doc?.map((i, idx: number) => (
                    <MenuItem key={idx} value={i.id}>
                      {i.createdBy} {new Date(i.createdAt).toLocaleString()}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl sx={{minWidth: 140, m: 1, height: 1}} size='small'>
            <InputLabel>Редакция-2</InputLabel>
            <Select
              labelId='demo-select-small'
              id='demo-select-small'
              value={secondEditorial}
              label='Редакция-2'
              onChange={chooseEditionSecond}
              required={true}
            >
              {!doc
                ? null
                : doc?.map((i, idx: number) => (
                    <MenuItem key={idx} value={i.id}>
                      {i.createdBy}: {new Date(i.createdAt).toLocaleString()}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Box>
      </form>
      {isError ? (
        <Typography sx={{textAlign: 'center', fontSize: '20px'}}>
          Невозможно сравнить редакции, на кыргызском или на русском нету
          варианта!
        </Typography>
      ) : (
        <Box sx={{docStyle}}>
          {isFetching ? (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Loader size={60} />
            </Box>
          ) : (
            <><ReactDiffViewer
                  hideLineNumbers={true}
                  showDiffOnly={true}
                  oldValue={url1?.txt1}
                  newValue={url1?.txt2}
                  splitView={true}
                  compareMethod={DiffMethod.SENTENCES} /><></></>
          )}
        </Box>
      )}
    </Container>
  )
}

const docStyle = () => ({
  m: 1,
  p: 1,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'rgba(5,55,112,0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(7,54,110,0.1)',
  },
})
