import {createSlice} from '@reduxjs/toolkit'

const changeLang = createSlice({
  name: 'change-lang',
  initialState: JSON.parse(localStorage.getItem('currentLang') as string) || {
    lng: 'kg',
  },
  reducers: {
    toggleLang: (state, action) => {
      state.lng = action.payload
      localStorage.setItem(
        'currentLang',
        JSON.stringify({
          lng: state.lng,
        }),
      )
    },
  },
})
export const {toggleLang} = changeLang.actions
export default changeLang.reducer
