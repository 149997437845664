/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react'
import EastIcon from '@mui/icons-material/East'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {Box, Button, Theme, Typography} from '@mui/material'

interface Props {
  currentWord: string | null
}

export const SearchText = ({currentWord}: Props) => {
  let position = -1
  const searchText = (next: boolean) => {
    const frame = document.getElementById('first-doc') as any
    const iframeContent = frame?.contentDocument
    const elems = iframeContent
      ?.getElementsByClassName('WordSection1')[0]
      ?.getElementsByClassName('wordSelected') as any

    if (elems) {
      if (!elems[0]) return
      if (elems[position]) elems[position].style.backgroundColor = ''
      const length = elems.length
      if (next) {
        if (elems[++position]) {
          elems[position].style.backgroundColor = 'yellow'
          elems[position].scrollIntoView({block: 'center', behavior: 'smooth'})
        } else {
          position = -1
        }
      } else {
        if (elems[--position]) {
          elems[position].style.backgroundColor = 'yellow'
          elems[position].scrollIntoView({block: 'center', behavior: 'smooth'})
        } else {
          position = length
        }
      }
    }
  }

  const nextScroll = () => {
    searchText(true)
  }
  const prevScroll = () => {
    searchText(false)
  }

  useEffect(() => {
    searchText(true)
  }, [currentWord])

  return (
    <>
      {currentWord && (
        <Box sx={search}>
          <Button onClick={prevScroll} startIcon={<KeyboardBackspaceIcon />} />
          <Typography sx={searchTexts}>{currentWord}</Typography>
          <Button onClick={nextScroll} startIcon={<EastIcon />} />
        </Box>
      )}
    </>
  )
}

const search = (theme: Theme) => ({
  position: 'fixed',
  zIndex: '11',
  left: 100,
  display: 'grid',
  textAlign: 'center',
  m: 'auto 0 auto -80px',
  [theme.breakpoints.between('xs', 'sm')]: {
    m: 'auto 0 auto -100px',
  },
})
const searchTexts = () => ({
  maxWidth: '150px',
})
