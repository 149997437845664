import React, {useEffect} from 'react'
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Theme,
} from '@mui/material'

import {setFilterDatas} from 'src/redux'
import {useAppDispatch, useAppSelector} from 'src/hooks'
import {AdvancedResearch, DatesGroup, Fields, Selects} from 'src/components'

interface IProps {
  oldData: {
    type: string
    isPercentage: boolean
    includeInvalid: boolean
  }
}

const NewRequest = ({oldData}: IProps) => {
  const methods = useForm<IForm>()
  const dispatch = useAppDispatch()
  const {register, handleSubmit, getValues, reset, setValue} = methods
  const lang = useAppSelector((state) => state.changeLang.lng)
  const {t} = useTranslation()
  const {includeInvalid, isPercentage, type} = oldData

  useEffect(() => {
    setValue('isPercentage', isPercentage)
    setValue('includeInvalid', includeInvalid)
  }, [])

  const onSearch: SubmitHandler<IForm> = (formData: IForm) => {
    dispatch(setFilterDatas(formData))
    localStorage.setItem(
      'search-by-text',
      lang == 'ru' ? getValues('searchByTextRu') : getValues('searchByTextKg'),
    )
    reset()
  }

  return (
    <Accordion sx={{maxWidth: '750px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={accardionTitle}
      >
        {t('sortDocs.SetFilter')}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={wrapper}>
          <FormProvider {...methods}>
            <input type='hidden' {...register('refClassId')} value={type} />
            <form onSubmit={handleSubmit(onSearch)}>
              <Box sx={form}>
                <Selects
                  type='5'
                  text={t('form.severalDoc')}
                  registerText='refTypeId'
                />
                <Selects
                  type='7'
                  text={t('form.organSelect')}
                  registerText='authoritiesId'
                />
                <DatesGroup
                  text={t('form.dates.title')}
                  exactDate='dateAdopted'
                  dateFrom='dateAdoptedFrom'
                  datedTo='dateAdoptedTo'
                />
                <Grid container spacing={2} sx={{mt: '2px'}}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Fields
                      text='номер'
                      registerText='number'
                      inputType='number'
                    />
                  </Grid>
                  <Grid
                    sx={{width: '100px'}}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                  >
                    <Selects
                      type='22'
                      text='статус'
                      registerText='refStatusId'
                    />
                  </Grid>
                </Grid>
                <Fields
                  text={t('form.byName')}
                  registerText={lang === 'ru' ? 'nameRus' : 'nameKyr'}
                />
                <Fields
                  text={t('form.byText')}
                  registerText={
                    lang === 'ru' ? 'searchByTextRu' : 'searchByTextKg'
                  }
                />
                <Selects
                  type='20'
                  text={t('form.keywords')}
                  registerText='kluchSlov'
                />
                <Selects
                  type='21'
                  text={t('form.generalLegalClassifier')}
                  registerText='klassificator'
                />
                <AdvancedResearch />
                <Divider />
                <Button
                  type={'submit'}
                  variant={'contained'}
                  sx={{mt: 1}}
                  startIcon={<SearchIcon />}
                >
                  {t('form.searchBtn')}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const accardionTitle = () => ({
  backgroundColor: 'primary.main',
  color: 'white',
})

const form = () => ({
  lineHeight: '30px',
  padding: '20px',
  background: 'white',
  borderRadius: '8px',
  maxWidth: '680px',
  boxShadow: '4',
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 2,
  [theme.breakpoints.between('xs', 'lg')]: {
    display: 'block',
  },
})

export default NewRequest
