import * as React from 'react'
import {useState} from 'react'
import ReactDiffViewer from 'react-diff-viewer-continued'
import {useTranslation} from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {TransitionProps} from '@mui/material/transitions'
import {useQuery} from '@tanstack/react-query'

import {$api, Loader} from 'src/utils'
import {convertToText} from 'src/utils/endpoints'
import {useAppSelector} from 'src/hooks/use-redux-hooks'
import {IDoc} from 'src/models/doc'

interface EditionProps {
  doc: IDoc
}

const CompareEditorial = ({doc}: EditionProps) => {
  const [open, setOpen] = React.useState(false)
  const [firstEditorial, setFirstEditorial] = useState('')
  const [secondEditorial, setSecondEditorial] = useState('')
  const chooseEditionFirst = (e: {
    target: {value: React.SetStateAction<string>}
  }) => {
    setFirstEditorial(e.target.value)
  }
  const lang = useAppSelector((state) => state.changeLang.lng)
  const chooseEditionSecond = (e: {
    target: {value: React.SetStateAction<string>}
  }) => {
    setSecondEditorial(e.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setFirstEditorial('')
    setSecondEditorial('')
    setOpen(false)
  }

  const {t} = useTranslation()
  const prov = firstEditorial && secondEditorial
  const {
    data: url1,
    isFetching,
    isError,
  } = useQuery(
    [firstEditorial, secondEditorial],
    async () => {
      return (
        await $api.get(
          `${convertToText}refId1=${firstEditorial}&refId2=${secondEditorial}&lang=${lang}`,
        )
      ).data
    },
    {enabled: !!prov},
  )

  enum DiffMethod {
    CHARS = 'diffChars',
    WORDS = 'diffWords',
    WORDS_WITH_SPACE = 'diffWordsWithSpace',
    LINES = 'diffLines',
    TRIMMED_LINES = 'diffTrimmedLines',
    SENTENCES = 'diffSentences',
    CSS = 'diffCss',
  }

  return (
    <>
      <Typography
        onClick={handleClickOpen}
        sx={{
          p: 1,
          cursor: 'pointer',
          '&:hover': {background: 'rgba(227,227,227,0.65)'},
        }}
      >
        {t('Comparison.comparison-of-editions')}
      </Typography>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: 'relative'}}>
          <Toolbar>
            <Typography sx={{ml: 2, flex: 1}} variant='h6' component='div'>
              {t('Comparison.comparison-of-editions')}
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <form onSubmit={(event) => event.preventDefault()}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 120,
            }}
          >
            <FormControl sx={{minWidth: 140, m: 1, height: 1}} size='small'>
              <InputLabel>Редакция-1</InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={firstEditorial}
                label='Редакция-1'
                onChange={chooseEditionFirst}
                required={true}
              >
                {!doc
                  ? null
                  : doc?.editions?.map((editionsId, idx: number) => (
                      <MenuItem key={idx} value={editionsId.id}>
                        {editionsId.nameRus}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl sx={{minWidth: 140, m: 1, height: 1}} size='small'>
              <InputLabel>Редакция-2</InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={secondEditorial}
                label='Редакция-2'
                onChange={chooseEditionSecond}
                required={true}
              >
                {!doc
                  ? null
                  : doc?.editions?.map((editionsId, idx: number) => (
                      <MenuItem key={idx} value={editionsId.id}>
                        {editionsId.nameRus}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>
        </form>
        {isError ? (
          <Typography sx={{textAlign: 'center', fontSize: '20px'}}>
            Невозможно сравнить редакции, на кыргызском или на русском нету
            варианта!
          </Typography>
        ) : (
          <Box sx={{docStyle}}>
            {isFetching ? (
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Loader size={60} />
              </Box>
            ) : (
              <ReactDiffViewer
                hideLineNumbers={false}
                showDiffOnly={true}
                oldValue={url1?.txt1}
                newValue={url1?.txt2}
                splitView={true}
                compareMethod={DiffMethod.SENTENCES}
              />
            )}
          </Box>
        )}
      </Dialog>
    </>
  )
}

const docStyle = () => ({
  m: 1,
  p: 1,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'rgba(5,55,112,0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(7,54,110,0.1)',
  },
})

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default CompareEditorial
