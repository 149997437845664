import {Box, Button, Typography} from '@mui/material'

export function NotFoundPage() {
  return (
    <Box sx={root}>
      <Box sx={label}>404</Box>
      <Typography sx={title}>Страница не существует.</Typography>
      <Typography align='center' sx={description}>
        Возможно, вы неправильно написали адрес, или страница была перемещена на
        другой URL.
      </Typography>
      <Box sx={{display: 'flex', justifyContent: 'center', mt: '40px'}}>
        <Button
          variant='contained'
          sx={{backgroundColor: 'black', textAlign: 'center'}}
        >
          <a style={{color: 'white', textDecoration: 'none'}} href='/'>
            На главную страницу
          </a>
        </Button>
      </Box>
    </Box>
  )
}

const root = {
  paddingTop: 20,
}
const label = {
  textAlign: 'center',
  fontWeight: 900,
  fontSize: 220,
  lineHeight: 1,
}
const title = {
  textAlign: 'center',
  fontWeight: 900,
  fontSize: 38,
}
const description = {
  maxWidth: 500,
  margin: 'auto',
}
