import {initReactI18next} from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// import httpApi from 'i18next-http-backend'

import {kg, ru} from './index'

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(initReactI18next)
  // .use(httpApi)
  .use(LanguageDetector)
  .init({
    resources: {
      ru: {translation: ru},
      kg: {translation: kg},
    },
    lng: 'ru',
    fallbackLng: 'kg',
    detection: {
      order: ['cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
  })

export default i18next
