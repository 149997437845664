import {useState} from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {useQuery} from '@tanstack/react-query'

import {$api} from 'src/utils'
import {getFeedbackResponse, getFeedbacks} from 'src/utils/endpoints'

interface Feedbacks {
  content: string
  id: number
  personEmail: string
  date: string
  personFullName: string
}

interface FeedbackResponse {
  massage: string
  dateTime: string
}

export const FeedbackHistory = () => {
  const [open, setOpen] = useState(false)
  const [id, setId] = useState<number>()
  const clickModal = () => setOpen((prev) => !prev)

  const {data: feedbacks} = useQuery(
    ['getfeedback'],
    async () =>
      (
        await $api.get<Feedbacks[]>(getFeedbacks, {
          params: {guid: localStorage.getItem('randomId')},
        })
      ).data,
    {refetchOnWindowFocus: true},
  )
  const {data: answer} = useQuery(
    [id, 'answer'],
    async () =>
      (
        await $api.post<FeedbackResponse[]>(getFeedbackResponse, {
          feedbackId: id,
        })
      ).data,
    {enabled: !!id, refetchOnMount: false, refetchOnWindowFocus: false},
  )

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: '400'}}>Имя</TableCell>
              <TableCell sx={{fontWeight: '400'}}>вопрос</TableCell>
              <TableCell sx={{fontWeight: '400'}}>Почта</TableCell>
              <TableCell sx={{fontWeight: '400'}}>Дата</TableCell>
              <TableCell sx={{fontWeight: '400'}}>Ответ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks?.map((row) => (
              <TableRow
                key={row.content}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component='th' scope='row'>
                  {row.personFullName}
                </TableCell>
                <TableCell>{row.content}</TableCell>
                <TableCell>{row.personEmail}</TableCell>
                <TableCell>{new Date(row.date).toLocaleDateString()}</TableCell>
                <TableCell>
                  <Button
                    variant='outlined'
                    onClick={() => (clickModal(), setId(row.id))}
                  >
                    Ответ
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={clickModal}>
        <DialogTitle fontWeight='400'>Посмотреть ответ на вопрос</DialogTitle>
        <DialogContent>
          {answer?.length ? (
            answer.map((i, id) =>
              i.massage ? (
                <Box mb='20px' key={id}>
                  <Box display={'flex'}>
                    <Typography>
                      <strong style={{fontWeight: 'bold'}}>Оператор: </strong>
                      {i.massage}
                    </Typography>
                  </Box>
                  {i.dateTime && (
                    <Box display={'flex'}>
                      <Typography>
                        <strong style={{fontWeight: 'bold'}}>Дата: </strong>
                        {new Date(i.dateTime).toLocaleDateString()}
                      </Typography>
                    </Box>
                  )}
                  <Divider />
                </Box>
              ) : (
                <Typography key={id}>Не отвечено</Typography>
              ),
            )
          ) : (
            <Typography>Не отвечено</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}
