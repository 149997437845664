import React from 'react'
import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import {Box, Button, Theme, useMediaQuery, useTheme} from '@mui/material'
import {useQuery} from '@tanstack/react-query'

import {$api} from 'src/utils'
import {
  deleteElectedDoc,
  getElectedDocs,
  setElectedDoc,
} from 'src/utils/endpoints'
import {addToSelected, removeDoc} from 'src/redux'
import {useAppDispatch, useAppSelector} from 'src/hooks'

interface IProps {
  doc: IListDoc
}

export const AddElectedDocs: React.FC<IProps> = ({doc}) => {
  const dispatch = useAppDispatch()
  const esiUser = localStorage.getItem('esi')
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const localElectedDocs = useAppSelector(
    (state) => state.addDocToSelectedDocs.selectedDocs,
  )

  const {data, refetch} = useQuery(
    ['get-electedDocs'],
    async () =>
      (
        await $api.post(
          getElectedDocs,
          {},
          {params: {guid: localStorage.getItem('randomId')}},
        )
      ).data,
    {refetchOnMount: false, refetchOnWindowFocus: false, enabled: !!esiUser},
  )

  const electedDocs = esiUser ? data : localElectedDocs
  const findElectedDocs =
    electedDocs?.length &&
    electedDocs.find((elem: IListDoc) => elem.documentCode === doc.documentCode)

  const handleAddToDocs = (doc: IListDoc) => {
    const chose = localStorage.getItem('fav')
    if (chose === 'true') {
      dispatch(addToSelected(doc))
    } else {
      dispatch(removeDoc(doc.documentCode))
    }
  }

  const setDoc = async () => {
    await $api.post(
      setElectedDoc,
      {documentCode: doc.documentCode},
      {params: {guid: localStorage.getItem('randomId')}},
    )
    refetch()
  }
  const deleteDoc = async () => {
    await $api.delete(deleteElectedDoc, {
      params: {
        userSelectedDocument: doc.documentCode,
        guid: localStorage.getItem('randomId'),
      },
      withCredentials: true,
    })
    refetch()
  }

  return (
    <Box>
      {!esiUser ? (
        <Box sx={starIcon(mobile)} onClick={() => handleAddToDocs(doc)}>
          {findElectedDocs?.documentCode ? (
            <StarIcon
              sx={icon}
              onClick={() => localStorage.setItem('fav', 'false')}
            />
          ) : (
            <StarOutlineIcon
              sx={icon}
              onClick={() => localStorage.setItem('fav', 'true')}
            />
          )}
        </Box>
      ) : (
        <Button sx={starIcon(mobile)}>
          {findElectedDocs?.documentCode ? (
            <StarIcon onClick={deleteDoc} />
          ) : (
            <StarOutlineIcon onClick={setDoc} />
          )}
        </Button>
      )}
    </Box>
  )
}

const icon = (theme: Theme) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
})

const starIcon = (mobile: boolean) => ({
  width: '20px',
  paddingLeft: mobile ? '10px' : '20px',
  '&:hover': {
    color: 'green',
  },
})
