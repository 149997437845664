import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {Box, Button, Container, useMediaQuery, useTheme} from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import {useQuery} from '@tanstack/react-query'

import {$api} from 'src/utils'
import {deleteElectedDoc, getElectedDocs} from 'src/utils/endpoints'
import {removeDoc} from 'src/redux'
import {ChangeLang, useAppDispatch, useAppSelector} from 'src/hooks'

interface IDoc {
  documentCode: string
  nameKg: string
  nameRu: string
  status: string
  lastEdition: number
}

export const SelectedDocs = () => {
  const esiUser = localStorage.getItem('esi')
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const theme = useTheme()
  const {lang} = useParams()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const localElectedDocs = useAppSelector(
    (state) => state.addDocToSelectedDocs.selectedDocs,
  )

  const {data, refetch} = useQuery(
    ['get-electedDocs'],
    async () =>
      (
        await $api.post(
          getElectedDocs,
          {},
          {params: {guid: localStorage.getItem('randomId')}},
        )
      ).data,
    {enabled: !!esiUser},
  )
  const deleteDoc = async (code: string) => {
    await $api.delete(deleteElectedDoc, {
      params: {
        userSelectedDocument: code,
        guid: localStorage.getItem('randomId'),
      },
      withCredentials: true,
    })
    refetch()
  }

  const electedDocs = esiUser ? data : localElectedDocs

  return (
    <Container maxWidth='lg' sx={{padding: mobile ? '0' : '50px'}}>
      <h1 style={{position: 'absolute', left: '-9999px', top: '-9999px'}}>
        Избранные документы
      </h1>
      <Box sx={{p: '30px 15px 15px 15px'}}>
        <Typography fontSize='18px'>
          {t('found-favorites')} {electedDocs?.length} {t('docs-favorites')}
        </Typography>
      </Box>
      <List sx={listDocsSx}>
        {electedDocs?.length ? (
          electedDocs.map((item: IDoc, idx: number) => {
            return (
              <Box key={idx} sx={typographyLink}>
                <ListItem
                  alignItems='flex-start'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <a
                    href={`/${item.documentCode}/edition/${item.lastEdition}/${lang}`}
                    style={{
                      textDecoration: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      fontFamily: 'Manrope, sans-serif',
                    }}
                  >
                    <Box sx={{mr: mobile ? '15px' : '20px'}}>
                      {item.status ? (
                        item.status === 'Действует' ? (
                          <CheckIcon
                            color='success'
                            fontSize={mobile ? 'medium' : 'large'}
                          />
                        ) : item.status !== 'Не вступил в силу' ? (
                          <ClearIcon
                            fontSize={mobile ? 'medium' : 'large'}
                            sx={{color: '#d53030'}}
                          />
                        ) : (
                          <Box width={mobile ? '25px' : '35px'} />
                        )
                      ) : (
                        <Box width={mobile ? '25px' : '35px'} />
                      )}
                    </Box>
                    <Box>
                      <Typography
                        sx={{display: 'inline'}}
                        component='span'
                        variant='body2'
                        color='text.primary'
                      >
                        {ChangeLang(item.nameRu, item.nameKg)}
                      </Typography>
                    </Box>
                  </a>
                  <Button
                    sx={deleteIcon}
                    onClick={() =>
                      esiUser
                        ? deleteDoc(item.documentCode)
                        : dispatch(removeDoc(item.documentCode))
                    }
                  >
                    <ClearIcon />
                  </Button>
                </ListItem>
                <Divider variant='inset' component='li' />
              </Box>
            )
          })
        ) : (
          <Typography
            sx={{textAlign: 'center', fontSize: '18px', mt: 6, mb: 6}}
          >
            {t('selectedDocs.docSelect')}
          </Typography>
        )}
      </List>
    </Container>
  )
}

const listDocsSx = () => ({
  // width: '100%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
})

const deleteIcon = () => ({
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
})

const typographyLink = () => ({
  transition: '0.3s',
  '&:hover': {
    background: '#eff4fc',
    transform: 'translateY(-4px)',
    transition: '0.3s',
    boxShadow: 1,
  },
})
