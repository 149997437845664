import React from 'react'
import {Pagination} from '@mui/material'

interface IProps {
  pageCount: number
  setNum: (num: number) => void
  num: number
}

export const UsePagination = ({pageCount, setNum, num}: IProps) => {
  return (
    <Pagination
      count={pageCount}
      onChange={(_: unknown, num: number) => setNum(num)}
      shape='circular'
      color='primary'
      page={num}
    />
  )
}
