import React, {useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import {useQuery} from '@tanstack/react-query'

import {$api} from '../../../utils'
import {getSelectSearchByRegister} from '../../../utils/endpoints'

import {SelectForOrganItem} from './select-item'

interface IProps {
  registerName: string
  type: string
  label: string
  title: string
  handleReset: (inputValue: void) => void
}

interface ISelectResponse {
  attributes: {
    id: string
    typeId: string | null
    parentName: string
  }
  children: ISelectResponse[]
  text: string
}

export const SelectForOrgan = ({
  type,
  label,
  registerName,
  handleReset,
  title,
}: IProps) => {
  const methods = useFormContext()
  const {setValue} = methods
  const [open, setOpen] = useState<boolean>(false)
  const [textValue, setTextValue] = useState<string>()
  const [inputValue, setInputValue] = useState<string>('')
  const {t} = useTranslation()

  const {data, isLoading} = useQuery(
    [type],
    async () => {
      return (await $api.get(`${getSelectSearchByRegister}${type}`)).data
    },
    {refetchOnWindowFocus: false, refetchOnMount: false},
  )

  const handleClickDialog = () => {
    setOpen((prev) => !prev)
  }

  useEffect(() => {
    handleReset(setInputValue(''))
  }, [handleReset])

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.detail === 2) {
      handleClickDialog()
    }
  }

  return (
    <Box sx={box}>
      <Typography>{title}</Typography>
      <Box sx={inputSelect}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {isLoading && <CircularProgress size={20} />}
                <KeyboardArrowDownIcon
                  sx={{cursor: 'pointer', color: 'black'}}
                  onClick={handleClickDialog}
                />
              </InputAdornment>
            ),
          }}
          value={inputValue}
          sx={{width: '100%'}}
          onClick={(e) => handleClick(e)}
          placeholder={label}
          size={'small'}
          variant={'standard'}
        />
        <Dialog onClose={handleClickDialog} open={open}>
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            {data
              ?.filter((i: ISelectResponse) => {
                return textValue
                  ? i.text.toLocaleLowerCase().includes(textValue)
                  : i
              })
              .map((item: ISelectResponse) => (
                <SelectForOrganItem
                  regisetName={registerName}
                  setInputValue={setInputValue}
                  handleClickDialog={handleClickDialog}
                  item={item}
                  setValue={setValue}
                  key={item.attributes.id}
                />
              ))}
          </DialogContent>
          <DialogActions>
            <TextField
              size='small'
              type={'text'}
              placeholder={t('search')}
              sx={{
                borderRadius: '9px',
                width: '100%',
                margin: '5px 25px 20px 25px',
              }}
              onChange={(e) => setTextValue(e.target.value)}
            />
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  )
}

const box = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  mt: 1,
  justifyContent: 'space-between',
  [theme.breakpoints.down(700)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const inputSelect = (theme: Theme) => ({
  width: '70%',
  [theme.breakpoints.down(700)]: {
    width: '100%',
  },
})
