import * as React from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Divider, Paper, Theme, Typography} from '@mui/material'
import Box from '@mui/material/Box'

import FilterNotionalNpa from './filter-notional-npa'

export const NotionalRegisterNpa = () => {
  const {t} = useTranslation()
  const [dataRegister, setDataRegister] = useState<IDataRegister>()

  const [windowWidth, setWindowWidth] = useState(window.screen.width)
  React.useEffect(() => {
    window.onresize = () => {
      setWindowWidth(window.screen.width)
    }
  }, [windowWidth])

  const registerData: IDataRegister[] = [
    {
      title: t('stateRegisterNpa.constitution'),
      aktCode: '0010',
      organCode: '',
    },
    {
      title: t('stateRegisterNpa.constitutional-law'),
      aktCode: '1020',
      organCode: '',
    },
    {
      title: t('stateRegisterNpa.codex'),
      aktCode: '0030',
      organCode: '',
    },
    {
      title: t('stateRegisterNpa.law'),
      aktCode: '0020',
      organCode: '',
    },
    {
      title: t('stateRegisterNpa.decrees'),
      aktCode: '0040',
      organCode: '',
    },
    {
      title: t('stateRegisterNpa.decreesJK'),
      aktCode: '0050',
      organCode: '0010.0010',
    },
    {
      title: t('stateRegisterNpa.decrees-governments'),
      aktCode: '0050',
      organCode: '0040.0010',
    },
    {
      title: t('stateRegisterNpa.decrees-national-bank'),
      aktCode: '0050',
      organCode: '0100.0010',
    },
    {
      title: t('stateRegisterNpa.decrees-central-commission'),
      aktCode: '0050',
      organCode: '0100.0050',
    },
    {
      title: t('stateRegisterNpa.decrees-OMSU'),
      aktCode: '0050',
      organCode: '0090',
    },
    {
      title: t('stateRegisterNpa.orders'),
      aktCode: '0200',
      organCode: '',
    },
  ]

  function handleChoose(item: IDataRegister) {
    setDataRegister(item)
  }

  return (
    <Box sx={paper}>
      <Paper sx={wrapperMenu}>
        {registerData.map((item, idx: number) => (
          <Box
            key={idx}
            sx={registers}
            onClick={() => {
              handleChoose(item)
            }}
          >
            <Button variant={'outlined'} size={'small'} sx={btn}>
              <Typography component='h2' fontSize='14px'>
                {item.title}
              </Typography>
            </Button>
            {windowWidth > 1200 ? <Divider orientation='vertical' /> : <></>}
          </Box>
        ))}
      </Paper>
      <FilterNotionalNpa dataRegister={dataRegister} />
    </Box>
  )
}

const paper = (theme: Theme) => ({
  pl: 15,
  pr: 15,
  mt: 2,
  [theme.breakpoints.down('lg')]: {
    p: 2,
    mt: 0,
  },
})

const wrapperMenu = () => ({
  display: 'flex',
  gap: '5px',
  p: '4px',
  width: '100%',
  overflow: 'auto',
})

const btn = (theme: Theme) => ({
  pr: 2.4,
  [theme.breakpoints.between('sm', 'lg')]: {
    fontSize: '10px',
    p: '2px',
  },
})

const registers = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: '0.3s',
  '&:hover': {
    background: '#eff4fc',
    transform: 'translateY(-4px)',
    transition: '0.3s',
  },
})
