import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {Box, Card, CardMedia, Theme, Typography} from '@mui/material'

import icon1 from 'src/assets/images/home/card_icon-1.webp'
import icon2 from 'src/assets/images/home/card_icon-2.webp'
import icon3 from 'src/assets/images/home/card_icon-3.webp'
import icon4 from 'src/assets/images/home/card_icon-4.webp'

interface ICard {
  img: string
  title: string
  path: string
}

export const SectionsCard = () => {
  const {t} = useTranslation()
  const {lang} = useParams()
  const cards: ICard[] = [
    {
      img: `${icon1}`,
      title: t('categories'),
      path: '/search-docs',
    },
    {
      img: `${icon3}`,
      title: t('notRegister.title'),
      path: '/notional-register-npa',
    },
    {
      img: `${icon2}`,
      title: t('pageNames.new-docs'),
      path: '/new-docs',
    },
    {
      img: `${icon3}`,
      title: t('feedback'),
      path: '/feedback',
    },
    {
      img: `${icon4}`,
      title: t('Статистика'),
      path: '/statistics',
    },
  ]

  return (
    <Box sx={block}>
      {cards.map((item, idx: number) => (
        <a
          href={`${item.path}/${lang}`}
          key={idx}
          style={{textDecoration: 'none'}}
        >
          <Card sx={card}>
            <CardMedia
              sx={img}
              component='img'
              alt={item.title}
              image={item.img}
            />
            <Typography sx={title}>{item.title}</Typography>
            <Box sx={icon}>
              <NavigateNextIcon
                sx={{color: 'white', verticalAlign: 'middle'}}
              />
            </Box>
          </Card>
        </a>
      ))}
    </Box>
  )
}

const block = (theme: Theme) => ({
  marginTop: '20px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: theme.spacing(2.1),
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
})

const card = (theme: Theme) => ({
  width: '215px',
  padding: '10px',
  borderRadius: '5px',
  transition: '0.5s',
  '&:hover': {
    backgroundColor: 'primary.main',
    transform: 'translateY(-5px)',
    boxShadow: 4,
    transition: '0.5s',
    color: 'white',
    '& > div': {
      background: 'white',
    },
    '& > div > svg': {
      color: 'black',
    },
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    width: '350px',
    padding: '20px',
  },
})

const img = (theme: Theme) => ({
  width: '120px',
  height: '120px',
  marginBottom: '20px',
  [theme.breakpoints.between('xs', 'sm')]: {
    width: '190px',
    height: '190px',
  },
})

const title = (theme: Theme) => ({
  height: '60px',
  width: '180px',
  marginBottom: '20px',
  fontWeight: '400',
  fontSize: '20px',
  lineHeight: '25px',
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: '24px',
  },
})

const icon = () => ({
  textAlign: 'center',
  marginLeft: 'auto',
  width: '40px',
  backgroundColor: 'primary.main',
  padding: '5px',
  borderRadius: '5px',
  transition: '0.5s',
})
