import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const getDocName = createSlice({
  name: 'get-doc-name',
  initialState: {
    docName: '',
    docNameRuKg: '',
  },
  reducers: {
    setDocName: (
      state,
      action: PayloadAction<{docName: string; docNameRuKg: string}>,
    ) => {
      state.docName = action.payload.docName
      state.docNameRuKg = action.payload.docNameRuKg
    },
  },
})
export const {setDocName} = getDocName.actions
export default getDocName.reducer
