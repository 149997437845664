import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {Box, FormControlLabel, Switch} from '@mui/material'

import {$api} from 'src/utils'
import {docControl} from 'src/utils/endpoints'

interface Props {
  isInControl: boolean
}

export const ControlDoc = ({isInControl}: Props) => {
  const {t} = useTranslation()
  const {id} = useParams()
  const user = JSON.parse(localStorage.getItem('esi') as string)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    $api.post(
      docControl,
      {},
      {
        params: {
          documentCode: id,
          Pin: user.pin,
          Email: user.email,
          IsInControl: event.target.checked,
        },
      },
    )
  }

  return (
    <Box>
      {user ? (
        <FormControlLabel
          control={
            <Switch defaultChecked={isInControl} onChange={handleChange} />
          }
          label={t('doc.control')}
        />
      ) : null}
    </Box>
  )
}
