import React, {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'

interface Props {
  docType: string
  setDocType: Dispatch<SetStateAction<string>>
}

export const DocTypesList = ({docType, setDocType}: Props) => {
  const {t} = useTranslation()

  const docTypes = [
    {
      title: t('stateRegisterNpa.constitution'),
      id: 'vid_akta 0010',
    },
    {
      title: t('stateRegisterNpa.constitutional-law'),
      id: 'vid_akta 1020',
    },
    {
      title: t('stateRegisterNpa.codex'),
      id: 'vid_akta 0030',
    },
    {
      title: t('stateRegisterNpa.law'),
      id: 'vid_akta 0020',
    },
    {
      title: t('stateRegisterNpa.decrees'),
      id: 'vid_akta 0040',
    },
    {
      title: t('stateRegisterNpa.decreesJK'),
      id: 'organ 0010',
    },
    {
      title: t('stateRegisterNpa.decrees-governments'),
      id: 'organ 0040',
    },
    {
      title: t('stateRegisterNpa.decrees-national-bank'),
      id: 'organ 0100.0010',
    },
    {
      title: t('stateRegisterNpa.decrees-central-commission'),
      id: 'organ 0100.0050',
    },
    {
      title: t('stateRegisterNpa.omsu'),
      id: 'organ 0090',
    },
  ]

  return (
    <FormControl>
      <InputLabel
        id='demo-simple-select-label'
        sx={{m: 0, '&.MuiInputLabel-shrink': {mt: '1px'}, mt: '-7px'}}
      >
        {t('docType')}
      </InputLabel>
      <Select
        value={docType}
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        label={t('docType')}
        onChange={(e) => setDocType(e.target.value)}
        size='small'
        sx={{width: '200px'}}
      >
        {docTypes.map((i, idx) => (
          <MenuItem key={idx} value={i.id}>
            {i.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
