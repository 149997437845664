import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
import {Box, Divider, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'

import {$api, Loader, UsePagination} from 'src/utils'
import {historyDocs} from 'src/utils/endpoints'
import {ChangeLang} from 'src/hooks'

export const DocHistory = () => {
  const user = JSON.parse(localStorage.getItem('esi') as string)
  const [num, setNum] = useState<number>(1)

  const {t} = useTranslation()

  const {data, isLoading} = useQuery(['historydocs', num], async () => {
    return (
      await $api.get<IListDoc[]>(historyDocs, {
        params: {pin: user.pin, pageSize: 10, pageNumber: num},
      })
    ).data
  })

  return (
    <Box sx={important}>
      <Typography textAlign='center' m='10px' variant='h5'>
        {t('История просмотра')}
      </Typography>
      <Box>
        {isLoading ? (
          <Box sx={{marginTop: '100px', marginLeft: '45%'}}>
            {<Loader size={80} />}
          </Box>
        ) : data?.length ? (
          data?.map((i, idx: number) => (
            <Box key={idx} sx={typographyLink}>
              <a
                href={`/${i.documentCode}`}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                  marginTop: '10px',
                  fontFamily: 'Manrope, sans-serif',
                }}
              >
                <CheckIcon color='success' fontSize='medium' />
                <Box>
                  <Typography
                    sx={typography}
                    component='span'
                    color='text.primary'
                  >
                    {ChangeLang(i.nameRu, i.nameKg)}
                  </Typography>
                </Box>
              </a>
              <Divider component='li' />
            </Box>
          ))
        ) : (
          <Typography textAlign='center' m='20px'>
            Данные отсутствуют
          </Typography>
        )}
        <Box sx={{display: 'flex', justifyContent: 'center', m: '20px'}}>
          {data?.length ? (
            <UsePagination
              setNum={setNum}
              num={num}
              pageCount={Math.ceil(data?.length / 12)}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

const important = () => ({
  m: 6,
  padding: '15px',
  background: 'white',
  borderRadius: '8px',
  boxShadow: '1',
})

const typography = () => ({
  fontSize: '14px',
})
const typographyLink = () => ({
  listStyle: 'none',
  transition: '0.3s',
  padding: '7px 5px',
  '&:hover': {
    background: '#eff4fc',
    transform: 'translateY(-4px)',
    transition: '0.3s',
    boxShadow: 1,
    borderRadius: 2,
  },
})
