import {ListDocs} from '../../modules'

export const NewDocsPage = () => {
  return (
    <>
      <h1 style={{position: 'absolute', left: '-9999px', top: '-9999px'}}>
        Новые документы
      </h1>
      <ListDocs />
    </>
  )
}
