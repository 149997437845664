import * as React from 'react'
import {useTranslation} from 'react-i18next'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'

import {IDoc, TEdition} from 'src/models/doc'

import CompareEditorial from './compare-editorial'
import CompareLanguages from './compare-languages'

interface CompareProps {
  edition: TEdition
  doc: IDoc
}

export const CompareDoc = ({edition, doc}: CompareProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {t} = useTranslation()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button variant='contained' size={'small'} onClick={handleClick}>
        {t('Comparison.comparison')}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <CompareLanguages id={edition?.id} />
        <CompareEditorial doc={doc} />
      </Menu>
    </div>
  )
}
