/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Button,
  Container,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuProps,
  Theme,
  Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import {alpha, styled} from '@mui/material/styles'

import {esiLogin} from 'src/utils/endpoints'
import {PopularDocs} from 'src/modules'

import {Accessibility} from './accessibility'
import ChangeLang from './change-lang'

type IMenuTitles = {
  title: string | React.ReactNode
  value: number
  path: string
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {lang} = useParams()
  const {t} = useTranslation()
  const user = localStorage.getItem('esi')

  const menuTitles: IMenuTitles[] = [
    {
      title: t('categories'),
      value: 0,
      path: '/search-docs',
    },
    {
      title: t('notRegister.title'),
      value: 4,
      path: '/notional-register-npa',
    },
    {
      title: t('feedback'),
      value: 5,
      path: '/feedback',
    },
    {
      title: t('pageNames.new-docs'),
      value: 6,
      path: '/new-docs',
    },
    {
      title: t('pageNames.selected-docs'),
      value: 7,
      path: '/selected-docs',
    },
    {
      title: t('pageNames.synopsis'),
      value: 8,
      path: '/synopsis',
    },
  ]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const randomSet = () => {
    const link = document.createElement('a')
    link.href = esiLogin(randomId())
    link.click()
    localStorage.setItem('randomId', randomId())
  }

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        width: '100%',
        zIndex: '3',
        marginTop: '0',
        position: 'static',
        top: 0,
      }}
    >
      <AppBar component='nav' sx={nav} position={'static'}>
        <Container>
          <Box sx={headerContainer}>
            <Box sx={links}>
              {menuTitles.map((title, idx) => (
                <a
                  href={`${title.path}/${lang}`}
                  key={idx}
                  style={{
                    color: 'white',
                    fontSize: '13px',
                    fontFamily: 'Rubik',
                    textDecoration: 'none',
                  }}
                >
                  {title.title}
                </a>
              ))}
              <PopularDocs />
              <Accessibility />
            </Box>
            <Box sx={menu}>
              <Button
                id='demo-customized-button'
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                variant='contained'
                disableElevation
                onClick={handleClick}
              >
                <MenuIcon />
              </Button>
              <StyledMenu
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <List component='nav' aria-label='main mailbox folders'>
                  {menuTitles.map((title, idx) => (
                    <Box key={idx}>
                      <a
                        href={title.path}
                        style={{
                          color: 'black',
                          textDecoration: 'none',
                        }}
                      >
                        <ListItemButton
                          sx={{height: '40px'}}
                          onClick={handleClose}
                          disableRipple
                        >
                          <ListItemText primary={title.title} />
                        </ListItemButton>
                      </a>
                      <Divider />
                    </Box>
                  ))}
                  <PopularDocs />
                  <Accessibility />
                  {/* {!user ? (
                    <ListItemButton>
                      <ListItemText>
                        <Typography
                          component={'a'}
                          onClick={randomSet}
                          sx={{color: 'black', textDecoration: 'none'}}
                        >
                          {t('pageNames.authorization')}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  ) : (
                    <Box
                      component={Link}
                      to='/profile'
                      sx={{cursor: 'pointer'}}
                    >
                      <AccountCircleIcon />
                    </Box>
                  )} */}
                </List>
              </StyledMenu>
            </Box>
            {/* <Box>
              {!user ? (
                <Typography component={'a'} sx={login} onClick={randomSet}>
                  {t('pageNames.authorization')}
                </Typography>
              ) : (
                <Box
                  component={Link}
                  to='/profile'
                  sx={{cursor: 'pointer', color: 'white', mr: '10px'}}
                >
                  <AccountCircleIcon sx={{color: 'white'}} />
                </Box>
              )}
            </Box> */}
            <Box sx={headerChangeLang}>
              <ChangeLang />
            </Box>
          </Box>
        </Container>
      </AppBar>
    </Box>
  )
}

const randomId = function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const headerContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const links = (theme: Theme) => ({
  display: 'flex',
  gap: 1.8,
  alignItems: 'center',
  [theme.breakpoints.between('xs', 1000)]: {
    display: 'none',
  },
})

const login = (theme: Theme) => ({
  color: 'white !important',
  marginRight: '20px',
  fontSize: '13px',
  textDecoration: 'none !important',
  cursor: 'pointer',

  [theme.breakpoints.between('xs', 1000)]: {
    display: 'none',
  },
})

const menu = (theme: Theme) => ({
  display: 'none',
  [theme.breakpoints.down(1000)]: {
    display: 'block',
  },
})
const nav = (theme: Theme) => ({
  padding: '10px ',
  [theme.breakpoints.between('sm', 1000)]: {
    padding: '11px 20px',
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    padding: '11px 0',
  },
})
const headerChangeLang = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
}

export default Header
