import React from 'react'
import {Box, Button, Modal, Theme, Typography} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, {SelectChangeEvent} from '@mui/material/Select'

import {IDoc} from 'src/models/doc'

interface IProps {
  doc: IDoc
}

const OpenData = ({doc}: IProps) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [type, setType] = React.useState('')
  const [value, setValue] = React.useState<boolean>()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function objectToXml(object: any) {
    let xml = ''
    for (const prop in object) {
      // eslint-disable-next-line no-prototype-builtins
      if (!object.hasOwnProperty(prop)) {
        continue
      }
      if (object[prop] == undefined) continue
      xml += '<' + prop + '>'
      if (typeof object[prop] == 'object')
        xml += objectToXml(new Object(object[prop]))
      else xml += object[prop]
    }
    return xml
  }

  const docType = value ? 'xml' : 'json'

  const download = () => {
    const jsonString = `data:text/${docType};chatset=utf-8,${encodeURIComponent(
      value ? objectToXml(doc) : JSON.stringify(doc, null, '\t'),
    )}`
    const link = document.createElement('a')
    link.href = jsonString
    link.download = `data.${docType}`
    link.click()
  }
  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string)
  }

  return (
    <Box sx={openData}>
      <Button
        onClick={handleOpen}
        size={'small'}
        sx={{p: '3px', bgcolor: '#0d3d85'}}
      >
        <span style={{background: 'white', padding: '1px'}}>OPEN</span>
        <span
          style={{
            backgroundColor: 'primary.main',
            padding: '2px',
            color: 'white',
          }}
        >
          DATA
        </span>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modal}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Открытые данные
          </Typography>
          <FormControl fullWidth sx={{mt: 1}}>
            <InputLabel id='demo-simple-select-label'>Тип данных</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={type}
              label='Тип данных'
              onChange={handleChange}
            >
              <MenuItem onClick={() => setValue(false)} value={1}>
                JSON
              </MenuItem>
              <MenuItem onClick={() => setValue(true)} value={2}>
                XML
              </MenuItem>
            </Select>
            <Button
              variant={'contained'}
              sx={{mt: 1}}
              onClick={() => typeof value !== 'undefined' && download()}
            >
              Скачать
            </Button>
          </FormControl>
        </Box>
      </Modal>
    </Box>
  )
}

const modal = () => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  boxShadow: 24,
  p: 4,
  borderRadius: '7px',
})
const openData = (theme: Theme) => ({
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'none',
  },
})
export default OpenData
