import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const localDatas = JSON.parse(localStorage.getItem('filterDatas') as string)

const filterDatas = createSlice({
  name: 'filterDatas',
  initialState: localDatas || {
    filterDatas: {},
  },
  reducers: {
    setFilterDatas: (state, action: PayloadAction<IForm | IClarifyForm>) => {
      state.filterDatas = action.payload
      localStorage.setItem(
        'filterDatas',
        JSON.stringify({
          filterDatas: action.payload,
        }),
      )
    },
  },
})

export const {setFilterDatas} = filterDatas.actions
export default filterDatas.reducer
