import React from 'react'
import {useFormContext} from 'react-hook-form'
import {Box, TextField, Theme, Typography} from '@mui/material'

interface Props {
  title: string
  registerText: string
  placeHolder: string
  type: string
}

const Field = ({title, registerText, placeHolder, type}: Props) => {
  const {register} = useFormContext()

  return (
    <Box sx={box}>
      <Typography>{title}</Typography>
      <Box sx={inputSelect}>
        <TextField
          sx={{width: '100%'}}
          type={type}
          {...register(registerText)}
          placeholder={placeHolder}
          size={'small'}
          variant={'standard'}
        />
      </Box>
    </Box>
  )
}

const box = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  mt: 1,
  justifyContent: 'space-between',
  [theme.breakpoints.down(700)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const inputSelect = (theme: Theme) => ({
  width: '70%',
  [theme.breakpoints.down(700)]: {
    width: '100%',
  },
})

export default Field
