import React from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import {Backdrop, CircularProgress} from '@mui/material'
import axios, {AxiosResponse} from 'axios'

import {getFile} from 'src/utils/endpoints'
import {useAppSelector} from 'src/hooks'
import {TEdition} from 'src/models/doc'

interface Props {
  edition: TEdition
  nameDoc: string
}

export const Download = ({nameDoc, edition}: Props) => {
  const lang = useAppSelector((state) => state.changeLang.lng)
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)

  const handleDownloadDocx = async () => {
    setOpen(true)
    axios
      .get(
        `${getFile}${edition?.id}&lang=${
          lang === 'ru' ? 'ru' : 'kg'
        }&isdownload=true`,
        {responseType: 'blob'},
      )
      .then((response: AxiosResponse) => {
        const div = document.createElement('header')
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }),
        )
        link.download = `${nameDoc}.docx`
        div.appendChild(link)
        link.click()
        setOpen(false)
      })
      .catch(() => {
        setOpen(false)
      })
  }

  return (
    <>
      <DownloadIcon
        onClick={handleDownloadDocx}
        fontSize={'large'}
        sx={{cursor: 'pointer', color: 'primary.main'}}
      />
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}
