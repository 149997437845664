import React, {useState} from 'react'
import {useQuery} from '@tanstack/react-query'
import axios, {AxiosResponse} from 'axios'

import {getMediaFile} from '../../utils/endpoints'

export const GetFile = () => {
  const [state, setState] = useState<string>()
  const queryParams = new URLSearchParams(window.location.search)
  const file = queryParams.get('fileName')
  const conditionElem = window.location.search
  const lastName = conditionElem.toString().slice(-3)
  const conditionType =
    lastName === 'pdf'
      ? 'application/pdf'
      : lastName === 'ocx'
      ? 'application/msword'
      : 'image/png'

  useQuery([file], () => {
    axios
      .get(`${getMediaFile}${file}`, {
        responseType: 'blob',
      })
      .then((response: AxiosResponse) => {
        const res = window.URL.createObjectURL(
          new Blob([response.data], {type: conditionType}),
        )
        setState(res)
      })
  })
  return <iframe src={state} style={{height: '100vh', width: '100vw'}} />
}
