import {useTranslation} from 'react-i18next'
import {AppBar, Box, Container, Theme, Typography} from '@mui/material'

import {LogoComponent} from './logo'

function Toolbar() {
  const {t} = useTranslation()
  return (
    <Box>
      <AppBar component='nav' position={'static'} color='secondary'>
        <Container sx={headerContainer}>
          <LogoComponent />
          <Typography sx={cbdTitle}>{t('content.title')}</Typography>
        </Container>
      </AppBar>
    </Box>
  )
}

const cbdTitle = (theme: Theme) => ({
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.primary.main,
  textAlign: 'right',
  width: '450px',
})

const headerContainer = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '40px 0',
  alignItems: 'center',
  [theme.breakpoints.between('xs', 1000)]: {
    display: 'none',
    padding: '0',
    margin: '0',
  },
})

export default Toolbar
