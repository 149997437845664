import React from 'react'
import {Control, Controller, FieldValues, Path} from 'react-hook-form'
import {Box, TextField} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

interface IDates<D extends FieldValues> {
  control: Control<D>
  label: string
  registerText: Path<D>
  click?: () => void
  type?: 'year'
  min?: string
  required?: boolean
}

export const Dates = <D extends AnyShape>(props: IDates<D>) => {
  const {
    click,
    label,
    registerText,
    control,
    type,
    min,
    required = false,
  } = props

  return (
    <Box sx={inputStyle}>
      <Controller
        control={control}
        name={registerText}
        render={({field: {name, onChange, ref, value = null}}) => {
          switch (type) {
            case 'year':
              return (
                <DatePicker
                  openTo={'year'}
                  views={['year']}
                  label={label}
                  value={value}
                  onChange={onChange}
                  minDate={dayjs('1980')}
                  maxDate={dayjs(new Date())}
                  renderInput={(params) => (
                    <TextField size='small' {...params} helperText={null} />
                  )}
                />
              )
            default:
              return (
                <DatePicker
                  // mask='__.__.____'
                  label={label}
                  minDate={min}
                  // maxDate={new Date()}
                  renderInput={({inputProps, ...rest}) => {
                    const params = {
                      ...rest,
                      inputProps: {
                        ...inputProps,
                        placeholder: '__.__.____',
                      },
                    }
                    return (
                      <TextField
                        required={required}
                        {...params}
                        name={name}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size='small'
                        onClick={click}
                        color={'primary'}
                        sx={{
                          '.MuiInputBase-input': {
                            fontWeight: 'bold',
                            color: '#0d3d85',
                          },
                        }}
                      />
                    )
                  }}
                  inputRef={ref}
                  value={value}
                  inputFormat='DD.MM.YYYY'
                  onChange={(value) =>
                    onChange(dayjs(value).format('YYYY-MM-DD'))
                  }
                  // toolbarFormat='__.__.____'
                />
              )
          }
        }}
      />
    </Box>
  )
}

const inputStyle = () => ({
  display: 'flex',
  gap: '7px',
  alignItems: 'flex-end',
})
