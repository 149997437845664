import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

import {ChangeLang} from 'src/hooks'
import {IDoc, Iklassificator} from 'src/models/doc'

interface ICredentials {
  doc: IDoc
}

const Credentials: React.FC<ICredentials> = ({doc}) => {
  const {t} = useTranslation()

  const credentials = [
    {
      label: 'titleDoc',
      name: ChangeLang(doc?.nameRus, doc?.nameKyr),
    },
    {
      label: 'form.severalDoc',
      name: ChangeLang(doc?.refTypeId?.nameRus, doc?.refTypeId?.nameKyr),
    },
    {
      label: 'Орган',
      name: ChangeLang(
        doc?.authoritiesId?.nameRus,
        doc?.authoritiesId?.nameKyr,
      ),
    },
    {
      label: 'form.nateOfRegisMU',
      name: doc?.dateRegistration
        ? new Date(doc?.dateRegistration).toLocaleDateString('ru-RU')
        : null,
    },
    {
      label: 'form.numberOfRegisMU',
      name: doc?.numberRegistration,
    },
    {
      label: 'Номер',
      name: doc?.number,
    },
    {
      label: 'form.Status',
      name: ChangeLang(doc?.status?.nameRus, doc?.status?.nameKyr),
    },
    {
      label: 'form.dateAdopted',
      name: doc?.dateAdopted
        ? new Date(doc?.dateAdopted).toLocaleDateString('ru-RU')
        : null,
    },
    {
      label: 'form.dateAdoptedTo',
      name: doc?.dateInclusion
        ? new Date(doc?.dateInclusion).toLocaleDateString('ru-RU')
        : null,
    },
    {
      label: 'form.dateOfPublication',
      name: doc?.datePublication
        ? new Date(doc?.datePublication).toLocaleDateString('ru-RU')
        : null,
    },
    {
      label: 'form.publicationSource',
      name: ChangeLang(
        doc.sourcePublicationsId?.nameRus,
        doc.sourcePublicationsId?.nameKyr,
      ),
    },
    {
      label: 'form.dateOfEntry',
      name: doc?.dateOfEntry
        ? new Date(doc?.dateOfEntry).toLocaleDateString('ru-RU')
        : null,
    },
    {
      label: 'form.generalLegalClassifier',
      name: doc?.klassificator?.map((item: Iklassificator, idx) => (
        <Typography key={idx}>
          {ChangeLang(item?.nameRus, item?.nameKyr)}
        </Typography>
      )),
    },
    {
      label: 'form.keywords',
      name: doc?.kluchSlov?.map((item: Iklassificator, idx) => (
        <Typography key={idx}>
          {ChangeLang(item?.nameRus, item?.nameKyr)}
        </Typography>
      )),
    },
  ]

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label='simple table'>
          <TableBody>
            {credentials.map((i, index) => (
              <TableRow
                key={index}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component='th' scope='row' sx={titles}>
                  {t(i.label)}
                </TableCell>
                <TableCell align='left' sx={titles}>
                  {i.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

const titles = () => ({
  fontSize: '15px',
})

export default Credentials
