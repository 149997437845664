import * as React from 'react'
import {useTranslation} from 'react-i18next'
import AdsClickIcon from '@mui/icons-material/AdsClick'
import InvertColorsIcon from '@mui/icons-material/InvertColors'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import LinkIcon from '@mui/icons-material/Link'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Fade from '@mui/material/Fade'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export const Accessibility = () => {
  const theme = useTheme()
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const mobile = useMediaQuery(theme.breakpoints.down(1000))

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChose = (name: string) => {
    document.getElementById('main-body')?.classList.toggle(name)
  }

  return (
    <Box mr='10px'>
      <Box onClick={handleClick} sx={{cursor: 'pointer'}} mt='5px'>
        {mobile ? (
          <>
            <Divider />
            <Typography
              sx={{fontSize: '16px', ml: '16px', mt: '5px', color: 'black'}}
            >
              {t('accessibility.title')}
            </Typography>
          </>
        ) : open ? (
          <VisibilityIcon fontSize='small' />
        ) : (
          <VisibilityOffIcon fontSize='small' />
        )}
      </Box>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {items.map((i) => {
          const body = document.getElementById('main-body')

          return (
            <MenuItem
              sx={{display: 'flex', gap: '10px', order: 1, padding: '0 15px'}}
              key={i.className}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={body?.classList.contains(i.className)}
                    onClick={() => handleChose(i.className)}
                  />
                }
                label={t(i.name)}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

const items = [
  {
    name: 'accessibility.colorGrey',
    icon: <VisibilityOffIcon />,
    className: 'helper-core-grey',
  },
  {
    name: 'accessibility.colorInvert',
    icon: <InvertColorsIcon />,
    className: 'helper-core-invert',
  },
  {
    name: 'accessibility.links',
    icon: <LinkIcon />,
    className: 'helper-core-underline',
  },
  {
    name: 'accessibility.zoom',
    icon: <ZoomInIcon />,
    className: 'helper-core-zoom',
  },
  {
    name: 'accessibility.activeElement',
    icon: <KeyboardIcon />,
    className: 'helper-core-tabHighlight',
  },
  {
    name: 'accessibility.cursorBlack',
    icon: <AdsClickIcon />,
    className: 'helper-core-blmouse',
  },
  {
    name: 'accessibility.cursorWhite',
    icon: <AdsClickIcon color='disabled' />,
    className: 'helper-core-whmouse',
  },
]

const style = (theme: Theme) => ({
  mr: '10px',
  [theme.breakpoints.down(1000)]: {
    ml: '20px',
  },
})
