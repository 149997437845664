import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Box,
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {LogoComponent} from 'src/components/layout/logo'
import bannerImg from 'src/assets/images/home/banner_img.webp'

export const Banner = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

  return (
    <Container sx={content(mobile, theme)}>
      <Box sx={imgs}>
        <LogoComponent />
      </Box>
      <Typography sx={title}>{t('content.title')}</Typography>
    </Container>
  )
}

const content = (mobile: boolean, theme: Theme) => ({
  width: '100%',
  backgroundImage: `url(${bannerImg})`,
  p: 12,
  backgroundSize: 'cover',
  borderRadius: mobile ? '10px' : '',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.between('xs', 'sm')]: {
    backgroundSize: 'cover',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    p: '130px 40px',
    gap: '30px',
  },
})

const imgs = (theme: Theme) => ({
  display: 'none',
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'block',
  },
})

const title = (theme: Theme) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '25px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '22px',
  },
  fontSize: '28px',
  maxWidth: '470px',
  color: 'white',
  fontWeight: '500',
  lineHeight: '44px',
})
