import React, {useEffect, useState} from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import {Avatar, Box, Theme} from '@mui/material'

export const ScrollTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 350) {
        setShowTopBtn(true)
      } else {
        setShowTopBtn(false)
      }
    })
  }, [])
  return (
    <Box sx={scrollTop}>
      {showTopBtn && (
        <Avatar
          sx={{m: 1, bgcolor: '#032b6c', cursor: 'pointer'}}
          onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }}
        >
          <ArrowUpwardIcon />
        </Avatar>
      )}
    </Box>
  )
}

const scrollTop = (theme: Theme) => ({
  cursor: 'pointer',
  transition: '0.3s',
  position: 'fixed',
  right: '4rem',
  zIndex: '10',
  bottom: '4rem',
  '&:hover': {
    transform: 'scale(1.2)',
    transition: '0.3s',
  },
  [theme.breakpoints.between('xs', 'md')]: {
    right: '0',
    '&:hover': {
      transform: 'scale(1)',
      transition: '0.3s',
    },
  },
})
