import * as React from 'react'
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form'
import {Button, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

import {$api} from 'src/utils'
import {documentError} from 'src/utils/endpoints'
import {IDoc} from 'src/models/doc'

interface IPropsSelectedModal {
  open: boolean
  handleClose: () => void
  textError?: string
  doc: IDoc
}

interface IFeedback {
  content: string
}

const SelectedModal: React.FC<IPropsSelectedModal> = ({
  open,
  handleClose,
  textError,
  doc,
}) => {
  const {register, handleSubmit, reset} = useForm()
  const baseUrl = window.location.href

  const feedbackError: SubmitHandler<IFeedback | FieldValues> = async ({
    content,
  }) => {
    const newContent = `
      <strong>Ссылка на документ: </strong>
      <a href=${baseUrl}>${baseUrl}</a><br/> 
      <strong>ИНН оператора: ${doc.operatorINN}</strong> <br>
      <strong>Ошибка в тексте: </strong>${textError} <br/> 
      <strong>Коментарий пользователя: </strong>${content}
    `
    try {
      await $api.post(
        documentError,
        {content: newContent},
        {
          headers: {'Content-type': 'application/json'},
        },
      )
      reset()
      handleClose()
    } catch (e) {
      throw Error
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <form onSubmit={handleSubmit(feedbackError)}>
          <Box sx={style}>
            <Box sx={{display: 'grid'}}>
              <Typography id='modal-modal-description' sx={{mt: 2}}>
                Орфографическая ошибка в тексте:
              </Typography>
              <Typography variant={'body2'} sx={{color: 'red'}}>
                {textError}
              </Typography>
            </Box>
            <Typography id='modal-modal-description' sx={{mt: 2}}>
              Послать сообщение об ошибке автору? <br />
              Ваш браузер останется на той же странице.
            </Typography>
            <TextField
              fullWidth={true}
              size={'small'}
              {...register('content')}
              placeholder={'Комментарий для автора (необязательно):'}
              sx={{mt: 1}}
            />
            <Box sx={{display: 'flex', gap: 1, mt: 1}}>
              <Button variant={'contained'} type={'submit'}>
                Отправить
              </Button>
              <Button variant={'contained'} color='error' onClick={handleClose}>
                Отмена
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}
const style = {
  overflow: 'auto',
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  lineHeight: '50px',
}
export default SelectedModal
