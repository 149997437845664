import React from 'react'
import {Path, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'
import {Box, Theme, Typography} from '@mui/material'

import {Dates} from './date'

interface IDate {
  text: string | null
  exactDate?: Path<IForm>
  dateFrom: Path<IForm>
  datedTo: Path<IForm>
}

export default function DatesGroup(props: IDate) {
  const {text, exactDate, dateFrom, datedTo} = props
  const methods = useFormContext()
  const {control, resetField} = methods
  const {t} = useTranslation()

  const clickA = () => {
    resetField(dateFrom)
    resetField(datedTo)
  }
  const clickB = () => {
    exactDate && resetField(exactDate)
  }
  const clear = () => {
    exactDate && resetField(exactDate)
    resetField(dateFrom)
    resetField(datedTo)
  }

  return (
    <Box sx={{mt: 1}}>
      <Typography>{text}</Typography>
      <Box sx={dates}>
        {exactDate && (
          <Dates
            control={control}
            label={t('form.dates.exact')}
            registerText={exactDate}
            click={clickA}
          />
        )}
        <Dates
          control={control}
          label={t('form.dates.diapason')}
          registerText={dateFrom}
          click={clickB}
        />
        <Dates
          control={control}
          label={'-'}
          registerText={datedTo}
          click={clickB}
        />
        <Box sx={{cursor: 'pointer'}}>
          <ClearIcon
            onClick={clear}
            sx={{
              color: '#666666',
              fontSize: '30px',
              background: '#f8f8f8',
              mb: '-15px',
              p: '5px',
              borderRadius: '7px',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const dates = (theme: Theme) => ({
  display: 'flex',
  gap: 1,
  m: '15px 0',
  justifyContent: 'space-between',
  textAlign: 'center',
  [theme.breakpoints.between('xs', 1100)]: {
    flexWrap: 'wrap',
  },
})
