import React from 'react'
import {CircularProgress} from '@mui/material'

interface ILoaderProps {
  size: number
}

export const Loader = ({size}: ILoaderProps) => {
  return <CircularProgress size={size} />
}
