import React from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Theme,
} from '@mui/material'

import {setFilterDatas} from 'src/redux'
import {useAppDispatch} from 'src/hooks'
import {DatesGroup, Fields, Selects} from 'src/components'

const ClarifyRequest = ({props}: {props: IClarifyForm}) => {
  // eslint-disable-next-line react/prop-types
  const {refTypeId, authoritiesId, refStatusId, kluchSlov, number} = props

  const methods = useForm<IClarifyForm>({
    defaultValues: {
      ...props,
    },
  })
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const {register, handleSubmit} = methods

  const onSearch = (formData: IClarifyForm) => {
    dispatch(setFilterDatas(formData))
  }

  return (
    <Accordion sx={{maxWidth: '750px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={accardionTitle}
      >
        {t('sortDocs.RefineRequest')}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={wrapper}>
          <FormProvider {...methods}>
            <input type='hidden' {...register('refClassId')} />
            <form onSubmit={handleSubmit(onSearch)}>
              <Box sx={form}>
                <Selects
                  type='5'
                  text={t('form.severalDoc')}
                  registerText='refTypeId'
                  defaultValue={refTypeId}
                />
                <Selects
                  type='7'
                  text={t('form.organSelect')}
                  registerText='authoritiesId'
                  defaultValue={authoritiesId}
                />
                <DatesGroup
                  text={t('form.dates.title')}
                  exactDate='dateAdopted'
                  dateFrom='dateAdoptedFrom'
                  datedTo='dateAdoptedTo'
                />
                <Grid container spacing={2} sx={{mt: '2px'}}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Fields
                      text='номер'
                      registerText='number'
                      inputType='number'
                      calarifyValue={number}
                    />
                  </Grid>
                  <Grid
                    sx={{width: '100px'}}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                  >
                    <Selects
                      type='22'
                      text='статус'
                      registerText='refStatusId'
                      defaultValue={refStatusId}
                    />
                  </Grid>
                </Grid>
                <Selects
                  type='20'
                  text='ключевые слова'
                  registerText='kluchSlov'
                  defaultValue={kluchSlov}
                />
                <Divider />
                <Button
                  type={'submit'}
                  variant={'contained'}
                  sx={{mt: 1}}
                  startIcon={<SearchIcon />}
                >
                  {t('form.searchBtn')}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const accardionTitle = () => ({
  backgroundColor: 'primary.main',
  color: 'white',
  borderRadius: '7px 7px 0 0',
})

const form = () => ({
  lineHeight: '30px',
  padding: '20px',
  background: 'white',
  borderRadius: '8px',
  maxWidth: '680px',
  boxShadow: '4',
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 2,
  [theme.breakpoints.between('xs', 'lg')]: {
    display: 'block',
  },
})

export default ClarifyRequest
