import {configureStore} from '@reduxjs/toolkit'

import addDocToSelectedDocs from './reducer/add-doc-to-selected-docs'
import changeLang from './reducer/change-lang'
import getDocName from './reducer/get-doc-name'
import filterDatas from './reducer/get-filter-datas'

export const store = configureStore({
  reducer: {
    getDocName,
    addDocToSelectedDocs,
    changeLang,
    filterDatas,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['filterDatas/setFilterDatas'],
        ignoredPaths: ['filterDatas.filterDatas'],
      },
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
