import {createTheme} from '@mui/material'
import {ruRU as coreRuRU} from '@mui/material/locale'
import {ruRU} from '@mui/x-date-pickers/locales'

import RubikBold from '../assets/fonts/Rubik-Bold.ttf'
import RubikMedium from '../assets/fonts/Rubik-Medium.ttf'
import RubikRegular from '../assets/fonts/Rubik-Regular.ttf'
import RubikSemiBold from '../assets/fonts/Rubik-SemiBold.ttf'

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#0d3d85',
      },
      secondary: {
        main: '#ffffff',
      },
    },
    typography: {
      fontFamily: ['Rubik'].join(),
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: '400',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Rubik';
            font-weight: 400;
            font-display: swap;
            src: local('Rubik'), local('Rubik-Regular'), url(${RubikRegular}) format('truetype');
          },
          @font-face {
            font-family: 'Rubik';
            font-weight: 500;
            font-display: swap;
            src: local('Rubik'), local('Rubik-Medium'), url(${RubikMedium}) format('truetype');
          },
          @font-face {
            font-family: 'Rubik';
            font-weight: 600;
            font-display: swap;
            src: local('Rubik'), local('Rubik-SemiBold'), url(${RubikSemiBold}) format('truetype');
          },
          @font-face {
            font-family: 'Rubik';
            font-weight: 700;
            font-display: swap;
            src: local('Rubik'), local('Rubik-Bold'), url(${RubikBold}) format('truetype');
          },
          body {
            background-color: #fafafa;
            padding: 0;
            margin: 0;
          },
          .free_widget .uwaw-footer {
            display: none !important
          }
        `,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  },
  ruRU,
  // {},
  coreRuRU,
)
