import * as React from 'react'
import {Container} from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import {FeedbackForm} from './form'
import {FeedbackHistory} from './history'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const Feedback = () => {
  const [value, setValue] = React.useState(0)
  const user = JSON.parse(localStorage.getItem('esi') as string)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Container sx={{mt: '20px'}}>
      {user ? (
        <Box>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab
                sx={{fontSize: '16px', fontWeight: '400'}}
                label='Обратная связь'
                {...a11yProps(0)}
              />
              <Tab
                sx={{fontSize: '16px', fontWeight: '400'}}
                label='История обращений'
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <FeedbackForm />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FeedbackHistory />
          </TabPanel>
        </Box>
      ) : (
        <FeedbackForm />
      )}
    </Container>
  )
}
