import React from 'react'
import {FieldValues, UseFormSetValue} from 'react-hook-form'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'

interface IProps {
  handleClickDialog: () => void
  item: ISelectResponse
  setValue: UseFormSetValue<FieldValues>
  setInputValue: (val: string) => void
  regisetName: string
}

interface ISelectResponse {
  attributes: {
    id: string
    typeId: string | null
    parentName: string
  }
  children: ISelectResponse[]
  text: string
}
export const SelectForOrganItem = ({
  item,
  handleClickDialog,
  setValue,
  setInputValue,
  regisetName,
}: IProps) => {
  const getValueSelects = (item: ISelectResponse) => {
    handleClickDialog()
    setInputValue(item.text)
    setValue(regisetName, item.attributes.id)
  }

  return (
    <div>
      <Accordion key={item.attributes.id}>
        {item.children ? (
          <AccordionSummary
            aria-controls='panel1a-content'
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '0',
              padding: '0',
            }}
          >
            <ExpandMoreIcon sx={{padding: '0 5px'}} />
            <Typography
              onClick={() => getValueSelects(item)}
              sx={{maxWidth: '75%'}}
            >
              {item.text}
            </Typography>
          </AccordionSummary>
        ) : (
          <Typography
            sx={{
              padding: '10px 2px 10px 35px',
              cursor: 'pointer',
              maxWidth: '95%',
            }}
            onClick={() => getValueSelects(item)}
          >
            {item.text}
          </Typography>
        )}
        <AccordionDetails>
          {item.children &&
            item.children.map((i) => (
              <SelectForOrganItem
                regisetName={regisetName}
                key={i.attributes.id}
                item={i}
                handleClickDialog={handleClickDialog}
                setInputValue={setInputValue}
                setValue={setValue}
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
