import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Box, FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {useQuery} from '@tanstack/react-query'

import {$api} from 'src/utils'
import {getValueSelects} from 'src/utils/endpoints'
import {ChangeLang} from 'src/hooks'

interface Props {
  filterByType: (id: number) => void
}

interface IStatus {
  code: string
  nameKyr: string
  nameRus: string
  parentCode: null
  tag: null
}

interface TypeEventTarget extends EventTarget {
  id: number
}

const SortStatusDocs = ({filterByType}: Props) => {
  const [value, setValue] = useState<string>('')
  const onsubmit = (e: TypeEventTarget) => {
    filterByType(e?.id)
  }

  const {data: select} = useQuery(
    ['get-selects'],
    async () => {
      return (await $api.get(`${getValueSelects}22`)).data
    },
    {refetchOnMount: false},
  )

  const {t} = useTranslation()

  return (
    <FormControl>
      <Box sx={{textAlign: 'center'}}>
        <InputLabel
          id='demo-simple-select-label'
          sx={{m: 0, mt: '-7px', '&.MuiInputLabel-shrink': {mt: '1px'}}}
        >
          {t('Status')}
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={value}
          label={'статус'}
          size={'small'}
          sx={{
            width: '200px',
            fontSize: '16px',
          }}
          onChange={(e) => setValue(e.target.value)}
        >
          {select &&
            select.map((i: IStatus, idx: number) => (
              <MenuItem
                key={idx}
                onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
                  onsubmit(e.target as TypeEventTarget)
                }
                id={i.code}
                value={i.code}
              >
                {ChangeLang(i.nameRus, i.nameKyr)}
              </MenuItem>
            ))}
        </Select>
      </Box>
    </FormControl>
  )
}
export default SortStatusDocs
