import {Container} from '@mui/material'

import {RootForm} from '../../components'

export const SearchDocs = () => {
  return (
    <Container>
      <RootForm />
    </Container>
  )
}
