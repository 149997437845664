import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import {IDoc} from 'src/models/doc'

interface IEditorial {
  doc: IDoc
}

interface IEditorialData {
  editionCode: number
  id: number
  nameKyr: string
  nameRus: string
}

export const Editorial = ({doc}: IEditorial) => {
  const navigate = useNavigate()
  const {lang} = useParams()
  const {id: docId, redaction} = useParams()

  const handleChangeEditorial = (id: string) => {
    navigate({pathname: `/${docId}/edition/${id}/${lang}`}, {replace: false})
  }

  return (
    <FormControl sx={{minWidth: 120}} size='small'>
      <InputLabel shrink>Редакция</InputLabel>
      <Select
        value={redaction as string}
        displayEmpty
        label='Редакция'
        notched
        onChange={(e) => handleChangeEditorial(e.target.value)}
      >
        {!doc
          ? null
          : doc?.editions?.map((i: IEditorialData, idx: number) => (
              <MenuItem key={idx} value={i.id}>
                {i.nameRus}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}
