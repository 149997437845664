import React from 'react'
import {FieldValues, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Theme,
} from '@mui/material'
import Select, {SelectChangeEvent} from '@mui/material/Select'

interface IProps {
  filters: (filters: {sortBy: string; direction: string}) => void
}

const SortForListDocs = ({filters}: IProps) => {
  const [sortList, setSorList] = React.useState<string>('')
  const change = (event: SelectChangeEvent) => {
    setSorList(event.target.value as string)
  }

  const {register, handleSubmit} = useForm()

  const onsubmit = handleSubmit((formData: FieldValues) => {
    const {sortDirection, sortBy} = formData
    const direction = sortDirection === true ? 'asc' : 'desc'
    if (sortBy) {
      filters({sortBy, direction})
    }
  })
  const {t} = useTranslation()
  return (
    <form onSubmit={onsubmit}>
      <FormControl fullWidth>
        <Box sx={filter}>
          <Box>
            <InputLabel
              id='demo-simple-select-label'
              sx={{
                p: 0,
                m: 0,
                mt: '-7px',
                '&.MuiInputLabel-shrink': {mt: '1px'},
              }}
            >
              {t('sortDocs.title')}
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={sortList}
              label={t('sortDocs.title')}
              sx={select}
              size={'small'}
              {...register('sortBy')}
              onChange={change}
            >
              <MenuItem value={'date'}>{t('sortDocs.byDate')}</MenuItem>
              <MenuItem value={'vid'}>{t('sortDocs.byVidDoc')}</MenuItem>
              <MenuItem value={'organ'}>{t('sortDocs.byOrgan')}</MenuItem>
            </Select>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
            <FormControlLabel
              control={<Checkbox {...register('sortDirection')} />}
              label={t('sortDocs.reverse')}
            />
            <Button
              type='submit'
              size={'small'}
              variant='contained'
              color={'primary'}
            >
              {t('sortDocs.filter')}
            </Button>
          </Box>
        </Box>
      </FormControl>
    </form>
  )
}

export default SortForListDocs

const filter = (theme: Theme) => ({
  display: 'block',
  [theme.breakpoints.between('sm', 'md')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
})

const select = (theme: Theme) => ({
  width: '300px',
  fontSize: '16px',
  [theme.breakpoints.between('xs', 'sm')]: {
    width: '200px',
    mr: '10px',
  },
})
