import React from 'react'
import {useTranslation} from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'

import Field from './field/field'
import {SelectForOrgan} from './select/slect-for-organ'
import {Date} from './date'

interface Props {
  handleReset: (inputValue: void) => void
}

const RegisterNpaForm = ({handleReset}: Props) => {
  const {t} = useTranslation()
  return (
    <Accordion sx={{marginTop: '10px', width: '100%'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={accardionTitle}
      >
        <Typography sx={{fontSize: '17px', fontWeight: '400'}}>
          {t('registerNpaForm.search')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{lineHeight: 3, paddingTop: '20px'}}>
        <SelectForOrgan
          title={t('registerNpaForm.type-of-act')}
          handleReset={handleReset}
          registerName='refTypeId'
          label={t('registerNpaForm.type-of-act')}
          type='5'
        />
        <Field
          title={t('registerNpaForm.name-of-the-act')}
          registerText={'actName'}
          placeHolder={t('registerNpaForm.name-of-the-act')}
          type={'text'}
        />
        <SelectForOrgan
          title={t('registerNpaForm.adopted-the-act')}
          handleReset={handleReset}
          registerName='organName'
          label={t('registerNpaForm.adopted-the-act')}
          type='7'
        />
        <Date
          title={t('registerNpaForm.date-of-acceptance')}
          placeHolder={t('registerNpaForm.date-of-acceptance')}
          registerText={'adoptedDate'}
        />
        <Field
          title={t('registerNpaForm.number-in-the-act')}
          registerText={'actNumber'}
          placeHolder={t('registerNpaForm.number-in-the-act')}
          type={'number'}
        />
        <Field
          title={t('registerNpaForm.registration-number')}
          registerText={'registrationNumber'}
          placeHolder={t('registerNpaForm.registration-number')}
          type={'number'}
        />
        <Date
          title={t('registerNpaForm.date-of-registration')}
          placeHolder={t('registerNpaForm.date-of-registration')}
          registerText={'registrationDate'}
        />
        <Date
          title={t('registerNpaForm.date-of-publication')}
          placeHolder={t('registerNpaForm.date-of-publication')}
          registerText={'publishDate'}
        />
        <SelectForOrgan
          title={t('registerNpaForm.publication-source')}
          handleReset={handleReset}
          registerName='sourcePublicationsText'
          label={t('registerNpaForm.publication-source')}
          type='19'
        />
        <SelectForOrgan
          title={t('registerNpaForm.status')}
          handleReset={handleReset}
          registerName='refStatusId'
          label={t('registerNpaForm.status')}
          type='22'
        />
        <Date
          title={t('registerNpaForm.reestr-npa')}
          placeHolder={t('registerNpaForm.reestr-npa')}
          registerText={'inclusionDate'}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const accardionTitle = () => ({
  backgroundColor: 'primary.main',
  color: 'white',
  borderRadius: '8px 8px 0 0',
})

export default RegisterNpaForm
