import React from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Theme} from '@mui/material'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'

import {UsePagination} from '../../../utils'

import {TableOfNpa} from './table-of-npa'

interface IProps {
  data: IRegisterDocs
  setNum: (num: number) => void
  num: number
}

const DocsByRegisterNpa = ({data, setNum, num}: IProps) => {
  const count: null | number = data ? data.filteredResultsCount : null
  const pageCount = Math.ceil((count as number) / 10)
  const {t} = useTranslation()

  return (
    <>
      {data?.data && (
        <Typography sx={totaltitle}>
          {t('found')} {count} {t('docs')}
        </Typography>
      )}
      <List sx={listDocsSx}>
        <TableOfNpa docs={data} />
        <Box
          sx={{
            mb: 4,
            mt: 2,
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <UsePagination setNum={setNum} num={num} pageCount={pageCount} />
        </Box>
      </List>
    </>
  )
}

const totaltitle = (theme: Theme) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    marginBottom: '15px',
    fontSize: '16px',
  },
  fontSize: '19px',
})

const listDocsSx = (theme: Theme) => ({
  width: '100%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  // [theme.breakpoints.between("sm", "md")]: {
  //     width: "500px",
  // },
  [theme.breakpoints.between('xs', 'sm')]: {
    // width: "300px",
    m: 1,
    p: 1,
  },
})

export default DocsByRegisterNpa
