import React, {useEffect, useState} from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {Box, Button, Divider, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {useQuery} from '@tanstack/react-query'

import {Dates} from '../../components'
import {postNotionalRegister} from '../../utils/endpoints'

import DocsByRegisterNpa from './docs/docs-by-register-npa'
import RegisterNpaForm from './register-npa-form'

interface IPostData {
  aktCode: string
  adoptedYear: Date
  from: string | Date
  organCode: string
  to: string | Date
}

interface Props {
  dataRegister?: IDataRegister
}

const FilterNotionalNpa = ({dataRegister}: Props) => {
  const methods = useForm()
  const [formData, setFormData] = useState<IPostData | FieldValues>()
  const [num, setNum] = useState<number>(1)
  const {handleSubmit, control, reset} = methods

  useEffect(() => {
    setFormData({
      ...formData,
      aktCode: dataRegister?.aktCode,
      organCode: dataRegister?.organCode,
    })
    setNum(1)
  }, [dataRegister?.aktCode, dataRegister?.organCode])

  const {data, isLoading, isError} = useQuery(
    [num, formData],
    async () => {
      const getRegisterDocs = await fetch(
        `${postNotionalRegister}pageNumber=${num}&pageSize=10`,
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(formData),
        },
      )
      return await getRegisterDocs.json()
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!(formData?.aktCode),
    },
  )
  
  const condition = data
  const onSearchByRegister: SubmitHandler<IPostData | FieldValues> = (data) => {
    setFormData({
      ...data,
      organCode: dataRegister?.organCode,
      aktCode: dataRegister?.aktCode,
      adoptedYear: new Date(data.adoptedYear).getFullYear(),
    })
    reset()
  }
  const {t} = useTranslation()

  function handleReset() {
    return ''
  }

  return (
    <Box sx={filter}>
      <>
        <>
          {dataRegister?.title ? (
            <>
              <Box sx={{p: 2}}>
                <Typography sx={titleBreakpoints}>
                  {dataRegister?.title}
                </Typography>
                <Divider />
                {isError && (
                  <Typography
                    variant={'h5'}
                    sx={{color: 'red', textAlign: 'center'}}
                  >
                    {t('filterNationalNpa.serverError')}
                  </Typography>
                )}
                <FormProvider {...methods}>
                  <form
                    onSubmit={handleSubmit(onSearchByRegister)}
                    style={{paddingTop: '1rem'}}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flexWrap: 'wrap',
                      }}
                    >
                      <Dates
                        control={control}
                        type='year'
                        label={t('filterNationalNpa.date-picker')}
                        registerText='adoptedYear'
                      />
                    </Box>
                    <RegisterNpaForm handleReset={handleReset} />
                    <Box sx={{display: 'flex', gap: 1}}>
                      <Button
                        variant={'contained'}
                        endIcon={<CloseIcon />}
                        sx={{mt: 1}}
                        onClick={() => {
                          reset()
                          handleReset()
                        }}
                      >
                        {t('filterNationalNpa.clear')}
                      </Button>
                      <Button
                        variant={'contained'}
                        endIcon={<SearchIcon />}
                        type={'submit'}
                        sx={{mt: 1}}
                      >
                        {t('filterNationalNpa.find')}
                      </Button>
                    </Box>
                  </form>
                </FormProvider>
              </Box>
              <Divider />
              {formData?.aktCode && formData?.aktCode && isLoading ? (
                <Typography
                  sx={{
                    mt: 6,
                    fontSize: '28px',
                    textAlign: 'center',
                  }}
                >
                  Загрузка...
                </Typography>
              ) : (
                <DocsByRegisterNpa data={condition} num={num} setNum={setNum} />
              )}
            </>
          ) : (
            <Box sx={{display: 'grid', justifyContent: 'center'}}>
              <Typography variant={'h1'} sx={text}>
                {t('filterNationalNpa.npa-title')}
              </Typography>
            </Box>
          )}
        </>
      </>
    </Box>
  )
}

export default FilterNotionalNpa

const filter = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    // marginTop: "36rem",
  },
})

const titleBreakpoints = (theme: Theme) => ({
  fontSize: '22px',
  fontWeight: 500,

  [theme.breakpoints.up('sm')]: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '22px',
    whiteSpace: 'wrap !important',
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px',
    whiteSpace: 'wrap !important',
  },
})

const text = (theme: Theme) => ({
  textAlign: 'center',
  mt: 6,
  mb: 6,
  width: '100%',
  fontSize: '30px',
  [theme.breakpoints.between('xs', 'md')]: {
    fontSize: '14px',
  },
})
