import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import {Box, Container, Theme, Typography} from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Stack from '@mui/material/Stack'

import {routes} from '../app/root-route'
import {useAppSelector} from '../hooks/use-redux-hooks'

export const Breadcums = () => {
  const location = useLocation()
  const {lang} = useParams()
  const name = location.pathname.slice(1).replace('/kg', '').replace('/ru', '')
  const {t} = useTranslation()
  const docName = useAppSelector((state) => state.getDocName.docName)

  return (
    <Container sx={{pt: 3}}>
      <Stack spacing={2} sx={{pl: 0}}>
        {location.pathname.replace('kg', '').replace('ru', '') ===
        '/' ? null : (
          <Breadcrumbs separator='›' aria-label='breadcrumb'>
            <a
              href={`/${lang}`}
              style={{
                textDecoration: 'none',
                fontSize: '15px',
                fontWeight: '400',
                width: '300px',
                color: 'gray',
              }}
            >
              {t(`pageNames.main`)}
            </a>
            {isNaN(Number(name.slice(0, 1))) ? (
              routes.map(({path}) => {
                const curentPath =
                  location.pathname.replace('/kg', '').replace('/ru', '') ===
                  path.replace('/:lang', '')
                if (curentPath) {
                  return (
                    <Box key={path}>
                      <a
                        href={`${path.replace('/:lang', '')}/${lang}`}
                        style={{
                          textDecoration: 'none',
                          fontSize: '15px',
                          fontWeight: '400',
                          width: '300px',
                          color: curentPath ? '#054ebb' : 'gray',
                        }}
                      >
                        {t(`pageNames.${name}`)}
                      </a>
                    </Box>
                  )
                }
              })
            ) : (
              <Typography sx={docname}>{docName}</Typography>
            )}
          </Breadcrumbs>
        )}
      </Stack>
    </Container>
  )
}

const docname = (theme: Theme) => ({
  textDecoration: 'none',
  fontSize: '15px',
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontWeight: '400',
  whiteSpace: 'nowrap',
  color: '#054ebb',
  // fontFamily: 'Manrope, sans-serif',
  maxWidth: '600px',
  [theme.breakpoints.between('xs', 'md')]: {
    maxWidth: '300px',
  },
})
