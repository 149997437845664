import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import {useReactToPrint} from 'react-to-print'
import {TabContext, TabPanel} from '@mui/lab'
import {Box, Container, Tab, Tabs, Theme, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'

import {$api, Loader} from 'src/utils'
import {getDocumentByCode, getEditionCode} from 'src/utils/endpoints'
import {setDocName} from 'src/redux'
import {ChangeLang, useAppDispatch, useAppSelector} from 'src/hooks'

import Credentials from './sections/credentials'
import {Document} from './sections/document'
import ManageDoc from './sections/document-methods'
import HighlightedText from './sections/document-methods/highlighted-text'
import ReferencesDocs from './sections/referring-documents'

export const Doc = () => {
  const {id, redaction} = useParams()
  const incorrectDocNumber = !/^[-0-9]+$/.test(id as string)

  const dispatch = useAppDispatch()
  const [byText, setByText] = React.useState<string>('')
  const [docScroll, setDocScroll] = React.useState<boolean>(true)
  const [tabValue, setTabValue] = React.useState<string>('1')
  const {t} = useTranslation()
  const {pathname} = useLocation()
  const {lng} = useAppSelector((state) => state.changeLang)
  const user = JSON.parse(localStorage.getItem('esi') as string)

  const {data: doc, isLoading} = useQuery(
    ['get-doc-by-code', id],
    async () => {
      const res = (
        await $api.get(`${getDocumentByCode}${id}`, {
          params: user?.pin && {pin: user?.pin},
        })
      ).data
      return res
    },
    {enabled: !incorrectDocNumber},
  )

  const {data: edition, isLoading: loading} = useQuery(
    [redaction, lng],
    async () => {
      return (await $api.get(`${getEditionCode}${redaction}&lang=${lng}`)).data
    },
    {enabled: !!redaction},
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [printDoc, setPrintDoc] = useState<any>()
  const refText = useRef<HTMLDivElement | null>(null)
  const nameDoc = ChangeLang(doc?.nameRus, doc?.nameKyr)
  const [textHighlanderToPrint, setTextHighlanderToPrint] = useState<any>()

  useEffect(() => {
    if (doc) {
      dispatch(
        setDocName({
          docName: nameDoc,
          docNameRuKg: `${doc?.nameKyr} | ${doc?.nameRus}`,
        }),
      )
    }
  }, [nameDoc])

  const handleChange = (event: React.SyntheticEvent, newValue: string) =>
    setTabValue(newValue)

  const currentRef = useCallback(() => {
    return printDoc.curent
  }, [
  ])

  const onBeforeGetContent = useCallback(async () => {
    const text = refText?.current
    const doc = printDoc?.current
    if (text && doc) {
      const tagName = doc.getElementsByTagName('div')[0]
      const newTag = document.createElement('div')
      for (let i = 1; i < tagName.children.length; i++) {
        const clone = tagName.children[i].cloneNode(true)
        newTag.append(clone)
        if (i == 3) break
      }
      if (newTag.children.length) {
        text.insertBefore(newTag, text.firstElementChild)
      }
    }
  }, [
    refText?.current,
    refText?.current?.insertBefore,
    textHighlanderToPrint,
    nameDoc,
  ])

  // const handlePrintDoc = useReactToPrint({
  //   content: () => printDoc?.current,
  //   pageStyle: `@page { margin: 30px !important;  }`,
  //   removeAfterPrint: true,
  //   onBeforePrint: () => {
  //     setDocScroll(false)
  //   },
  //   onAfterPrint: () => {
  //     setDocScroll(true)
  //   },
  // })

  const handlePrintText = useReactToPrint({
    content: currentRef as any,
    pageStyle: `@page { margin: 50rem !important; padding: 50px !important; }`,
    documentTitle: nameDoc,
    onBeforeGetContent,
    onAfterPrint: () => {
      setTextHighlanderToPrint(undefined)
    },
    removeAfterPrint: true,
  })

  // const conditionToPrint = textHighlanderToPrint
  //   ? handlePrintText
  //   : handlePrintDoc
  const printClick = () => {
    printDoc
  }
  return (
    <>
      <Box sx={docPage}>
        <div>
          {!incorrectDocNumber && isLoading ? (
            <Box sx={{textAlign: 'center', marginTop: '100px'}}>
              <Loader size={90} />
            </Box>
          ) : doc ? (
            <Box sx={{width: '100%', typography: 'body1'}}>
              {pathname.includes('doc-preview') ? (
                <Document
                  loading={loading}
                  docScroll={docScroll}
                  edition={edition}
                  doc={doc}
                  searchWord={byText}
                  refDoc={setPrintDoc}
                  setTextHighlanderToPrint={setTextHighlanderToPrint}
                />
              ) : (
                <>
                  <TabContext value={tabValue}>
                    <Container sx={tabsBtns}>
                      <Box>
                        <Tabs
                          onChange={handleChange}
                          aria-label='secondary tabs example'
                          value={tabValue}
                        >
                          <Tab label='Документ' value='1' sx={tab} />
                          <Tab label={t('props')} value='2' sx={tab} />
                          <Tab label={t('refDocs')} value='3' sx={tab} />
                        </Tabs>
                      </Box>
                      <ManageDoc
                        doc={doc}
                        byText={byText}
                        printText={textHighlanderToPrint}
                        setByText={setByText}
                        // conditionToPrint={conditionToPrint}
                        edition={edition}
                        nameDoc={nameDoc}
                        // setEditorial={setEditorial}
                      />
                    </Container>
                    <TabPanel value='1' sx={{p: 0}}>
                      {/* <YourComponent /> */}
                      <Document
                        loading={loading}
                        docScroll={docScroll}
                        edition={edition}
                        doc={doc}
                        searchWord={byText}
                        refDoc={setPrintDoc}
                        // setTextHighlanderToPrint={setTextHighlanderToPrint}
                      />
                    </TabPanel>
                    <TabPanel value='2' sx={{p: 0}}>
                      <Credentials doc={doc} />
                    </TabPanel>
                    <TabPanel value='3' sx={{p: 0}}>
                      <ReferencesDocs doc={doc} />
                    </TabPanel>
                  </TabContext>
                  {/* <HighlightedText
                    refText={refText}
                    textHighlanderToPrint={textHighlanderToPrint}
                  /> */}
                </>
              )}
            </Box>
          ) : incorrectDocNumber ? (
            <Typography sx={{textAlign: 'center', fontSize: '18px', mt: 10}}>
              {ChangeLang(
                t('errors.incorrectDocNumber'),
                t('errors.incorrectDocNumber'),
              )}
            </Typography>
          ) : (
            <Typography sx={{textAlign: 'center', fontSize: '18px', mt: 10}}>
              {ChangeLang(t('errors.loadError'), t('errors.loadError'))}
            </Typography>
          )}
        </div>
      </Box>
    </>
  )
}

const docPage = () => ({
  pt: 2,
  mb: 5,
})

const tabsBtns = (theme: Theme) => ({
  borderBottom: 1,
  borderColor: 'divider',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 1,
  [theme.breakpoints.between('sm', 1000)]: {
    display: 'block',
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'block',
  },
})

const tab = (theme: Theme) => ({
  border: 'none',
  fontSize: '16px',
  fontWeight: '400',
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: '15px',
  },
})
