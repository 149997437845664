import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const addDocToSelectedDocs = createSlice({
  name: 'add-to-selected-docs',
  initialState: JSON.parse(localStorage.getItem('add-selected') as string) || {
    selectedDocs: [],
  },
  reducers: {
    addToSelected: (state, action: PayloadAction<IListDoc>) => {
      state.selectedDocs.push(action.payload)
      localStorage.setItem(
        'add-selected',
        JSON.stringify({
          selectedDocs: state.selectedDocs,
        }),
      )
    },
    removeDoc: (state, action: PayloadAction<string>) => {
      localStorage.setItem(
        'add-selected',
        JSON.stringify({
          selectedDocs: (state.selectedDocs = state.selectedDocs.filter(
            (elem: IListDoc) => elem.documentCode !== action.payload,
          )),
        }),
      )
    },
  },
})

export const {addToSelected, removeDoc} = addDocToSelectedDocs.actions
export default addDocToSelectedDocs.reducer
