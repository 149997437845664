import * as React from 'react'
import LanguageIcon from '@mui/icons-material/Language'
import {Button} from '@mui/material'
import Box from '@mui/material/Box'

import {toggleLang} from 'src/redux'
import {useAppDispatch} from 'src/hooks'

const ChangeLang = () => {
  const dispatch = useAppDispatch()
  const changeLang = (lang: string) => {
    dispatch(toggleLang(lang))
    setTimeout(() => {
      location.reload()
    }, 0)
  }

  return (
    <Box sx={{minWidth: 40, display: 'flex', alignItems: 'center'}}>
      <Button
        sx={{fontSize: '14px'}}
        onClick={() => changeLang('kg')}
        variant={'contained'}
        size={'small'}
        endIcon={<LanguageIcon />}
      >
        KG
      </Button>
      |
      <Button
        onClick={() => changeLang('ru')}
        sx={{fontSize: '14px'}}
        variant={'contained'}
        size={'small'}
        endIcon={<LanguageIcon />}
      >
        RU
      </Button>
    </Box>
  )
}

export default ChangeLang
