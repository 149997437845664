import React, {FC, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {Box, Container, Theme, useMediaQuery, useTheme} from '@mui/material'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import {useQuery} from '@tanstack/react-query'

import {$api, Loader, UsePagination} from 'src/utils'
import {getAllDocuments, getNewDocs} from 'src/utils/endpoints'
import {useAppSelector} from 'src/hooks'
import ExelIcon from 'src/assets/images/icons/exel.webp'
import WordIcon from 'src/assets/images/icons/word.webp'

import ClarifyRequest from './methods/clarify-request'
import {DocTypesList} from './methods/doc-types-list'
import {DownloadListDocs} from './methods/download'
import NewRequest from './methods/new-request'
import SortStatusDocs from './methods/sort-by-status'
import SortForListDocs from './methods/sort-docs'
import ListDoc from './list-doc'

interface IFilters {
  sortBy?: string
  direction?: string
  refStatusId?: string
}

export const ListDocs: FC = () => {
  const location = useLocation()
  const formData = useAppSelector((state) => state.filterDatas.filterDatas)
  const {t} = useTranslation()
  const refShow = useRef<HTMLDivElement>(null)
  const localDatas = JSON.parse(localStorage.getItem('filterDatas') as string)
  const [num, setNum] = useState<number>(1)
  const [filters, setFilters] = useState<IFilters>()
  const [filtersByType, setFiltersByType] = useState<number>()
  const [docType, setDocType] = useState<string>('')
  const isListDocs =
    location.pathname.replace('/kg', '').replace('/ru', '') === '/list-docs'

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const ModuleWrapper: React.ElementType = mobile ? Box : Container
  const {
    refTypeId,
    authoritiesId,
    kluchSlov,
    klassificator,
    sourcePublicationsId,
    refStatusId,
    number,
    refClassId,
    dateAdopted,
    dateAdoptedFrom,
    dateAdoptedTo,
    includeInvalid,
    isPercentage,
  } = formData
  const clarifyDatas = {
    authoritiesId,
    refStatusId,
    refTypeId,
    kluchSlov,
    number,
    dateAdopted,
    refClassId,
    dateAdoptedFrom,
    dateAdoptedTo,
    includeInvalid,
    isPercentage,
  }
  const newRequesDatas = {
    type: refClassId,
    includeInvalid: includeInvalid,
    isPercentage: isPercentage,
  }

  const bodyDatas = isListDocs
    ? {
        ...formData,
        sortBy: filters?.sortBy,
        sortDirection: filters?.direction,
        refStatusId: refStatusId ? refStatusId.code : filtersByType,
        refTypeId: refTypeId?.code,
        authoritiesId: authoritiesId?.code,
        kluchSlov: kluchSlov?.code,
        klassificator: klassificator?.code,
        sourcePublicationsId: sourcePublicationsId?.code,
      }
    : {
        classificator_Code: docType.split(' ')[0],
        reference_Code: docType.split(' ')[1],
        status: filtersByType,
      }

  const {isLoading, data} = useQuery(
    [num, filters, filtersByType, formData, docType],
    async () => {
      const requestUrl = isListDocs ? getAllDocuments : getNewDocs
      return (
        await $api.post(
          requestUrl + `?pageNumber=${num}&pageSize=12`,
          bodyDatas,
        )
      ).data
    },
  )

  const count = data
    ? isListDocs
      ? data?.filteredResultsCount
      : data?.totalResultsCount
    : null
  const pageCount = Math.ceil(count / 12)

  const filtered = data?.data?.filter((item: IListDoc) =>
    Boolean(item.nameKg || item.nameRu),
  )

  const executeScroll = () =>
    refShow.current?.scrollIntoView({behavior: 'smooth'})

  useEffect(() => {
    setNum(1)
  }, [filters, filtersByType, formData, docType])

  useEffect(() => {
    executeScroll()
  }, [data, isLoading])

  if (localDatas?.filterDatas.length > 10) {
    const docLimit = localDatas?.filterDatas.slice(-10)
    localStorage.setItem('filterDatas', JSON.stringify({filterDatas: docLimit}))
  }

  return (
    <ModuleWrapper sx={{mt: 2, mb: 2}} ref={refShow}>
      <Box padding={mobile ? '0 15px' : 0}>
        {isListDocs && (
          <Box sx={filterStyles}>
            <Box sx={newfilters}>
              <ClarifyRequest props={clarifyDatas} />
              <NewRequest oldData={newRequesDatas} />
            </Box>
            <SortForListDocs filters={setFilters} />
          </Box>
        )}
        <Box sx={sortBlock(mobile)}>
          <Typography sx={totaltitle(theme, mobile)}>
            {t('found')} {count} {t('docs')}
          </Typography>
          <SortStatusDocs filterByType={setFiltersByType} />
          {!isListDocs && (
            <DocTypesList docType={docType} setDocType={setDocType} />
          )}
          {count > 0 && (
            <Box
              sx={{
                display: 'flex',
                order: isListDocs ? 0 : 3,
                m: !isListDocs ? '10px 0' : 0,
              }}
            >
              <DownloadListDocs
                type='word'
                bodyDatas={bodyDatas}
                icon={WordIcon}
              />
              <DownloadListDocs
                type='exel'
                bodyDatas={bodyDatas}
                icon={ExelIcon}
              />
            </Box>
          )}
        </Box>
      </Box>
      {isLoading ? (
        <Box sx={{marginTop: '100px', marginLeft: '45%'}}>
          {<Loader size={80} />}
        </Box>
      ) : (
        <>
          <List sx={listDocsSx}>
            {filtered?.length ? (
              filtered.map((item: IListDoc) => {
                return (
                  <ListDoc
                    key={item?.documentCode}
                    item={item}
                    formData={{
                      nameRus: formData.nameRus,
                      nameKyr: formData.nameKyr,
                    }}
                  />
                )
              })
            ) : (
              <Typography
                sx={{textAlign: 'center', fontSize: '18px', mt: 6, mb: 6}}
              >
                {t('docsNotFound')}
              </Typography>
            )}
          </List>
          <Box
            sx={{
              mb: 4,
              mt: 2,
              textAlign: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <UsePagination setNum={setNum} num={num} pageCount={pageCount} />
          </Box>
        </>
      )}
    </ModuleWrapper>
  )
}

const listDocsSx = () => ({
  // width: '100%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  paddingRight: '10px',
})

const filterStyles = (theme: Theme) => ({
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '15px',
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'block',
    gap: '15px',
  },
})
const newfilters = (theme: Theme) => ({
  [theme.breakpoints.between('xs', 'md')]: {
    marginBottom: '20px',
  },
})

const sortBlock = (mobile: boolean) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '10px',
  mb: mobile ? 0 : 2,
  mt: 4,
})
const totaltitle = (theme: Theme, mobile: boolean) => ({
  fontSize: '18px',
  order: mobile ? 2 : '',
  mt: mobile ? 2 : 0,
  [theme.breakpoints.between('xs', 'sm')]: {
    marginBottom: '15px',
    fontSize: '16px',
  },
})
