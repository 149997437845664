import 'dayjs/locale/ru'

import React, {useEffect, useState} from 'react'
import ReactGA from 'react-ga4'
import {useTranslation} from 'react-i18next'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import userhelperlibrary from 'userhelperlibrary'

import {$api} from 'src/utils'
import {getEsiDatas} from 'src/utils/endpoints'
import {useAppSelector} from 'src/hooks'

import Layout from '../components/layout'
import {CompareEditorialHistory, DocPage, ServerFilesPage} from '../pages'
import {NotFoundPage} from '../pages/not-found'

import {routes} from './root-route'

function App() {
  const lng = useAppSelector((state) => state.changeLang.lng)
  const {i18n} = useTranslation()
  const location = useLocation()
  const pathname = location.pathname.slice(1)
  const [query, setQuery] = useState(location.hash.split('#')[1])
  const navigate = useNavigate()

  useEffect(() => {
    if (query) {
      navigate({search: `anchor=${query}`})
    }
  }, [query])

  useEffect(() => {
    ReactGA.initialize('G-8PKD0XQGRQ')
    ReactGA.send({hitType: 'pageview', page: window.location.pathname})
  }, [])

  useEffect(() => {
    userhelperlibrary({destroy: true, lang: 'ru'})
  }, [])

  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [lng, i18n])

  // if (location.pathname === '/esiredirect') {
  //   const link = document.createElement('a')
  //   link.href = `http://85.113.17.179:8083/api/v1/esiauth?guid=${localStorage.getItem(
  //     'randomId',
  //   )}`
  //   link.click()
  // }

  useQuery(
    ['esi'],
    async () => {
      const randomId = localStorage.getItem('randomId')

      const res =
        randomId &&
        (await $api.get(getEsiDatas(randomId), {withCredentials: true})).data
      if (res?.name) {
        localStorage.setItem('esi', JSON.stringify(res))
      } else {
        localStorage.removeItem('esi')
        localStorage.removeItem('randomId')
      }
      return res
    },
    {refetchOnWindowFocus: false, refetchOnMount: false},
  )

  if (pathname !== 'list-docs' && isNaN(Number(pathname.slice(0, 1)))) {
    localStorage.removeItem('search-by-text')
  }

  return (
    <Routes>
      <Route path={'/'} element={<Layout />}>
        {routes.map((route, id) => (
          <Route key={id} path={route.path} element={route.page} />
        ))}
      </Route>
      <Route path='/not-found' element={<NotFoundPage />} />
      <Route path='/getFile' element={<ServerFilesPage />} />
      <Route path='/doc-preview/:id' element={<DocPage />} />
      <Route path='/GetEditionHistory' element={<CompareEditorialHistory />} />
    </Routes>
  )
}

export default App
